import React, { useState, useRef, useEffect } from 'react';
import {
  MagnifyingGlassIcon,
  GlobeAltIcon,
  XCircleIcon,
  ArrowPathIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  ChatBubbleLeftRightIcon,
  BookOpenIcon,
  CpuChipIcon,
  CurrencyDollarIcon,
  LinkIcon
} from '@heroicons/react/24/outline';
import BaseLayout from './BaseLayout';
import { fetchLLMs, performSearch } from '../services/api';

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const [searchType, setSearchType] = useState('basic');
  const [includeDomains, setIncludeDomains] = useState('');
  const [excludeDomains, setExcludeDomains] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [llms, setLLMs] = useState([]);
  const [selectedLLM, setSelectedLLM] = useState('');
  const chatEndRef = useRef(null);
  const [enableEnrichment, setEnableEnrichment] = useState(true);


  // Fetch available LLMs on component mount
  useEffect(() => {
    const loadLLMs = async () => {
      try {
        const llmList = await fetchLLMs();
        setLLMs(llmList);
        if (llmList.length > 0) {
          setSelectedLLM(llmList[0].name);
        }
      } catch (err) {
        setError('Failed to load LLM options');
      }
    };
    loadLLMs();
  }, []);

  // Auto-scroll to bottom of chat
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

 // Add user message to chat
 setChatHistory(prev => [...prev, {
  type: 'user',
  content: query,
  timestamp: new Date().toISOString()
}]);

try {
  const data = await performSearch({
    query,
    searchType,
    includeDomains,
    excludeDomains,
    selectedLLM
  });
  
  // No need to check response.ok or call .json() as axios handles this
  if (data.status === 'error') {
    throw new Error(data.message);
  }

  // Add assistant's response to chat with token usage
  setChatHistory(prev => [...prev, {
    type: 'assistant',
    content: data.data,
    timestamp: new Date().toISOString(),
    tokenUsage: data.data.token_usage,
    modelUsed: data.data.model_used
  }]);
  
  setQuery('');
  
} catch (err) {
  setError(err.message || 'An error occurred during search');
  
  setChatHistory(prev => [...prev, {
    type: 'error',
    content: err.message || 'An error occurred during search',
    timestamp: new Date().toISOString()
  }]);
} finally {
  setLoading(false);
}
  };
  

const formatTokenUsage = (tokenUsage) => {
  if (!tokenUsage) return null;
  return {
    input: tokenUsage.prompt_tokens,
    output: tokenUsage.completion_tokens,
    total: tokenUsage.total_tokens
  };
};

const renderMessage = (message) => {
  if (message.type === 'user') {
    return (
      <div className="flex justify-end mb-4">
        <div className="bg-indigo-100 rounded-lg px-4 py-2 max-w-3/4">
          <p className="text-gray-900">{message.content}</p>
          <p className="text-xs text-gray-500 mt-1">
            {new Date(message.timestamp).toLocaleTimeString()}
          </p>
        </div>
      </div>
    );
  }

  if (message.type === 'error') {
    return (
      <div className="flex justify-center mb-4">
        <div className="bg-red-50 rounded-lg px-4 py-2 max-w-3/4">
          <p className="text-red-700">{message.content}</p>
          <p className="text-xs text-gray-500 mt-1">
            {new Date(message.timestamp).toLocaleTimeString()}
          </p>
        </div>
      </div>
    );
  }
  // Assistant message with analysis, search results, and token usage
  const tokens = formatTokenUsage(message.tokenUsage);
    
  return (
    <div className="flex justify-start mb-4">
      <div className="bg-white rounded-lg shadow-md px-4 py-2 max-w-3/4">
        {/* Model Info & Token Usage */}
        {(message.modelUsed || tokens) && (
          <div className="mb-3 border-b border-gray-200 pb-2">
            <div className="flex items-center justify-between text-xs text-gray-500">
              {message.modelUsed && (
                <div className="flex items-center">
                  <CpuChipIcon className="h-4 w-4 mr-1" />
                  <span>Model: {message.modelUsed}</span>
                </div>
              )}
              {tokens && (
                <div className="flex items-center">
                  <CurrencyDollarIcon className="h-4 w-4 mr-1" />
                  <span>
                    Tokens: {tokens.total} (Input: {tokens.input}, Output: {tokens.output})
                  </span>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Analysis Section */}
        {message.content.summary && (
          <div className="mb-4">
            <div className="flex items-center mb-2">
              <BookOpenIcon className="h-5 w-5 text-indigo-600 mr-2" />
              <h3 className="text-lg font-semibold text-gray-900">AI Analysis</h3>
            </div>
            <div className="prose max-w-none">
              <p className="text-gray-700">{message.content.summary}</p>
            </div>
          </div>
        )}

        {/* Key Findings Section */}
        {message.content.key_findings && (
          <div className="mb-4">
            <div className="flex items-center mb-2">
              <DocumentTextIcon className="h-5 w-5 text-indigo-600 mr-2" />
              <h3 className="text-lg font-semibold text-gray-900">Key Findings</h3>
            </div>
            <ul className="list-disc list-inside space-y-1">
              {message.content.key_findings.map((finding, idx) => (
                <li key={idx} className="text-gray-700">{finding}</li>
              ))}
            </ul>
          </div>
        )}

        {/* Search Results Section */}
        {message.content.results && (
          <div className="mb-4">
            <div className="flex items-center mb-2">
              <MagnifyingGlassIcon className="h-5 w-5 text-indigo-600 mr-2" />
              <h3 className="text-lg font-semibold text-gray-900">Search Results</h3>
            </div>
            <div className="space-y-3">
              {message.content.results.map((result, idx) => (
                <div key={idx} className="border-t pt-2">
                  <a
                    href={result.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-600 hover:text-indigo-800 font-medium"
                  >
                    {result.title || 'View Source'}
                  </a>
                  <p className="text-sm text-gray-600 mt-1">{result.content}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Sources Section */}
        {message.content.results && message.content.results.length > 0 && (
          <div className="mb-4">
            <div className="flex items-center mb-2">
              <LinkIcon className="h-5 w-5 text-indigo-600 mr-2" />
              <h3 className="text-lg font-semibold text-gray-900">Sources</h3>
            </div>
            <div className="space-y-2">
              {message.content.results.map((result, idx) => (
                <div key={idx} className="border-t pt-2">
                  <a
                    href={result.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-600 hover:text-indigo-800 font-medium flex items-center"
                  >
                    <DocumentTextIcon className="h-4 w-4 mr-2" />
                    {result.url}
                  </a>
                  {result.content && (
                    <p className="text-sm text-gray-600 mt-1 line-clamp-2">
                      {result.content}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Timestamp */}
        <p className="text-xs text-gray-500 mt-2">
          {new Date(message.timestamp).toLocaleTimeString()}
        </p>
      </div>
    </div>
  );
};

const clearChat = () => {
  setQuery('');
  setSearchType('basic');
  setIncludeDomains('');
  setExcludeDomains('');
  setChatHistory([]);
  setError('');
  };

  return (
    <BaseLayout>
      <div className="flex h-screen overflow-hidden">
        {/* Left sidebar with form */}
        <div className="w-1/3 bg-white p-4 overflow-y-auto border-r border-gray-200">
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 border-b border-gray-200">
              <h2 className="flex items-center text-xl font-semibold text-gray-900">
                <ChatBubbleLeftRightIcon className="h-6 w-6 mr-2" />
                AI-Powered Search
              </h2>
            </div>
            
            <div className="p-4">
              <form onSubmit={handleSearch} className="space-y-4">
                {/* LLM Selection */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Model
                  </label>
                  <select
                    value={selectedLLM}
                    onChange={(e) => setSelectedLLM(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  >
                    <option value="">Select a model</option>
                    {llms.map((llm) => (
                      <option key={llm.name} value={llm.name}>
                        {llm.display_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Search Type
                  </label>
                  <select
                    value={searchType}
                    onChange={(e) => setSearchType(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <option value="basic">Basic Search</option>
                    <option value="advanced">Advanced Search</option>
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 flex items-center">
                    <GlobeAltIcon className="h-4 w-4 mr-1" />
                    Include Domains
                  </label>
                  <input
                    type="text"
                    value={includeDomains}
                    onChange={(e) => setIncludeDomains(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="domain1.com, domain2.com"
                  />
                  <p className="text-xs text-gray-500">Comma-separated list of domains to include</p>
                </div>
                
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Ask a Question
                  </label>
                  <textarea
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="What would you like to know?"
                    rows="3"
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 flex items-center">
                    <GlobeAltIcon className="h-4 w-4 mr-1" />
                    Exclude Domains
                  </label>
                  <input
                    type="text"
                    value={excludeDomains}
                    onChange={(e) => setExcludeDomains(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="domain1.com, domain2.com"
                  />
                  <p className="text-xs text-gray-500">Comma-separated list of domains to exclude</p>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="enableEnrichment"
                    checked={enableEnrichment}
                    onChange={(e) => setEnableEnrichment(e.target.checked)}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="enableEnrichment" className="text-sm text-gray-700">
                    Enable AI Analysis
                  </label>
                </div>

                <div className="flex space-x-2">
                  <button
                    type="submit"
                    disabled={loading}
                    className="flex-1 flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                  >
                    {loading ? (
                      <>
                        <ArrowPathIcon className="animate-spin h-5 w-5 mr-2" />
                        Searching...
                      </>
                    ) : (
                      <>
                        <MagnifyingGlassIcon className="h-5 w-5 mr-2" />
                        Search
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={clearChat}
                    className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <XCircleIcon className="h-5 w-5 mr-2" />
                    Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Main content area with chat history */}
      <div className="flex-1 p-4 bg-gray-50">
        <div className="h-full flex flex-col">
          {/* Chat messages */}
          <div className="flex-1 overflow-y-auto px-4">
            {chatHistory.map((message, index) => (
              <div key={index}>
                {renderMessage(message)}
              </div>
            ))}
            <div ref={chatEndRef} />
          </div>
          {/* Error display */}
          {error && (
            <div className="p-4">
              <div className="mb-4 bg-red-50 border-l-4 border-red-400 p-4">
                <div className="flex">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-400" />
                  <div className="ml-3">
                    <p className="text-sm text-red-700">{error}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </BaseLayout>
);
};

export default SearchPage;