import React from 'react';
import { useNavigate } from 'react-router-dom';
import BaseLayout from './BaseLayout';
import { 
  ChatBubbleLeftRightIcon,
  NewspaperIcon,
  UserIcon,
  UserPlusIcon,
  BuildingLibraryIcon,
  CreditCardIcon,
  QuestionMarkCircleIcon,
  ViewfinderCircleIcon,
  DocumentIcon,
  MagnifyingGlassCircleIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';

const featureSections = [
  {
    title: "AI Assistants",
    description: "Powerful AI-powered tools for various tasks",
    gradient: "from-violet-500 to-purple-500",
    items: [
      {
        name: "Chat Bot",
        href: "/prompt-template",
        icon: ChatBubbleLeftRightIcon,
        description: "Interactive AI assistant with customizable prompt templates",
        color: "violet"
      },
      {
        name: "EnterpriseChatBot",
        href: "/rag",
        icon: BuildingLibraryIcon,
        description: "Advanced chat system with company knowledge integration",
        color: "purple"
      },
      {
        name: "DocumentQA",
        href: "/document-qa",
        icon: DocumentIcon,
        description: "Ask questions about your uploaded documents",
        color: "fuchsia"
      }
    ]
  },
  {
    title: "Content & Analysis",
    description: "Tools for content discovery and analysis",
    gradient: "from-blue-500 to-cyan-500",
    items: [
      {
        name: "Reddit Posts",
        href: "/reddit_posts",
        icon: NewspaperIcon,
        description: "Browse and analyze Reddit content",
        color: "blue"
      },
      {
        name: "Blog",
        href: "/blog",
        icon: NewspaperIcon,
        description: "Latest updates and articles",
        color: "cyan"
      },
      {
        name: "SocialAnalytics",
        href: "/social-analysis",
        icon: UserGroupIcon,
        description: "Analyze social media trends and engagement",
        color: "sky"
      },
      {
        name: "Notes",
        href: "/notes",
        icon: ViewfinderCircleIcon,
        description: "Create and manage your personal notes",
        color: "teal"
      }
    ]
  },
  {
    title: "Account & Settings",
    description: "Manage your account and preferences",
    gradient: "from-emerald-500 to-green-500",
    items: [
      {
        name: "Profile",
        href: "/profile",
        icon: UserIcon,
        description: "View and edit your profile settings",
        color: "emerald"
      },
      {
        name: "Register",
        href: "/register",
        icon: UserPlusIcon,
        description: "Create a new account",
        color: "green"
      },
      {
        name: "Billing",
        href: "/billing",
        icon: CreditCardIcon,
        description: "Manage your subscription and billing",
        color: "lime"
      }
    ]
  },
  {
    title: "Help & Resources",
    description: "Additional resources and information",
    gradient: "from-orange-500 to-amber-500",
    items: [
      {
        name: "Search",
        href: "/search",
        icon: MagnifyingGlassCircleIcon,
        description: "Search across all platform features",
        color: "orange"
      },
      {
        name: "About",
        href: "/about",
        icon: QuestionMarkCircleIcon,
        description: "Learn more about our platform",
        color: "amber"
      }
    ]
  }
];

const IconWrapper = ({ Icon, color }) => {
  const colorClasses = {
    violet: "text-violet-500 bg-violet-100 ring-violet-400",
    purple: "text-purple-500 bg-purple-100 ring-purple-400",
    fuchsia: "text-fuchsia-500 bg-fuchsia-100 ring-fuchsia-400",
    blue: "text-blue-500 bg-blue-100 ring-blue-400",
    cyan: "text-cyan-500 bg-cyan-100 ring-cyan-400",
    sky: "text-sky-500 bg-sky-100 ring-sky-400",
    teal: "text-teal-500 bg-teal-100 ring-teal-400",
    emerald: "text-emerald-500 bg-emerald-100 ring-emerald-400",
    green: "text-green-500 bg-green-100 ring-green-400",
    lime: "text-lime-500 bg-lime-100 ring-lime-400",
    orange: "text-orange-500 bg-orange-100 ring-orange-400",
    amber: "text-amber-500 bg-amber-100 ring-amber-400"
  };

  return (
    <div className={`
      p-4 rounded-xl shadow-lg
      ${colorClasses[color]}
      ring-1 ring-opacity-50
      transform transition-all duration-300
      group-hover:scale-110 group-hover:shadow-2xl
    `}>
      <Icon className="w-8 h-8" />
    </div>
  );
};

export default function HomeDashboard() {
  const navigate = useNavigate();

  return (
    <BaseLayout>
      <div className="px-4 py-8">
        <div className="max-w-7xl mx-auto">
          {/* Welcome Header */}
          <div className="mb-12 text-center">
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-violet-500 to-cyan-500 bg-clip-text text-transparent">
              Welcome to SUMMIT53
            </h1>
            <p className="text-xl text-text-secondary">Explore our AI-powered tools and services</p>
          </div>

          {/* Feature Sections */}
          <div className="space-y-12">
            {featureSections.map((section) => (
              <div key={section.title} className="bg-background-light rounded-xl p-6 shadow-lg">
                <h2 className={`text-2xl font-semibold mb-2 bg-gradient-to-r ${section.gradient} bg-clip-text text-transparent`}>
                  {section.title}
                </h2>
                <p className="text-text-secondary mb-6">{section.description}</p>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {section.items.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => navigate(item.href)}
                      className="group flex flex-col items-center text-left p-6 rounded-lg bg-background hover:bg-primary/5 transition-all duration-300 border border-primary/20"
                    >
                      <IconWrapper Icon={item.icon} color={item.color} />
                      <h3 className="text-lg font-medium mt-4 mb-2">{item.name}</h3>
                      <p className="text-text-secondary text-sm">{item.description}</p>
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}