import React, { useState, useEffect } from 'react';
import { fetchTokenUsage } from '../services/api';
import BaseLayout from './BaseLayout';
import { 
  Container, 
  Alert,
  Spinner 
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function BillingPage() {
  const [billingData, setBillingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0); // Add refresh key for manual refresh
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Use precise timestamps including time
        const endDate = new Date().toISOString();
        const startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString();
        
        const data = await fetchTokenUsage(startDate, endDate);
        setBillingData(data);
        setError(null);
      } catch (err) {
        console.error('Billing data error:', err);
        if (err.message.includes('Please log in')) {
          navigate('/login');
        } else {
          setError(err.message || 'Failed to fetch billing data');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    
    // Set up auto-refresh every minute
    const intervalId = setInterval(() => {
      setRefreshKey(oldKey => oldKey + 1);
    }, 60000);

    return () => clearInterval(intervalId);
  }, [navigate, refreshKey]);

  // Add manual refresh function
  const handleRefresh = () => {
    setRefreshKey(oldKey => oldKey + 1);
  };

  return (
    <BaseLayout>
      <Container className="mt-4">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-gray-100 dark:text-text-primary text-3xl font-bold">Billing Information</h1>
          <button 
            onClick={handleRefresh}
            className="px-4 py-2 bg-accent hover:bg-accent-dark text-white rounded-lg transition-colors duration-200 flex items-center gap-2"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
            Refresh
          </button>
        </div>
        
        
        {/* Summary Section */}
        <div className="mb-4">
        <h3 className="text-gray-100 dark:text-gray-800 text-xl font-semibold mb-3">
          Usage Summary
        </h3>
        <div className="space-y-2 text-gray-100 dark:text-gray-800">
          <p>Total Tokens: {billingData?.total_tokens?.toLocaleString()}</p>
          <p>Input Tokens: {billingData?.total_input_tokens?.toLocaleString()}</p>
          <p>Output Tokens: {billingData?.total_output_tokens?.toLocaleString()}</p>
          <p>Models Used: {billingData?.models_used}</p>
        </div>
        </div>

        {/* Detailed Usage Table */}
        <h3 className="text-gray-100 dark:text-text-primary text-xl font-semibold mb-3">Usage Details</h3>
        <div className="overflow-x-auto rounded-lg border border-gray-200 dark:border-gray-700">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-background-light">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-text-secondary uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-text-secondary uppercase tracking-wider">Model</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-text-secondary uppercase tracking-wider">Request Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-text-secondary uppercase tracking-wider">Input Tokens</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-text-secondary uppercase tracking-wider">Output Tokens</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-text-secondary uppercase tracking-wider">Total Tokens</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-text-secondary uppercase tracking-wider">Estimated Cost</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {billingData?.usage_details?.map((item, index) => (
                <tr 
                  key={index} 
                  className="bg-white dark:bg-background hover:bg-gray-50 dark:hover:bg-background-light transition-colors duration-200"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-text-secondary">{new Date(item.timestamp).toLocaleDateString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-text-secondary">{item.llm_model_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-text-secondary">{item.request_type}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-text-secondary">{item.input_tokens.toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-text-secondary">{item.output_tokens.toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-text-secondary">{(item.input_tokens + item.output_tokens).toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-text-secondary">${((item.input_tokens + item.output_tokens) * 0.0001).toFixed(4)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </BaseLayout>
  );
}

export default BillingPage;