import React, { useState } from 'react';
import {
  MagnifyingGlassIcon,
  HashtagIcon,
  ChartBarIcon,
  ClockIcon,
  ArrowPathIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import BaseLayout from './BaseLayout';
import { analyzeSocialMedia } from '../services/api';

const SocialMediaAnalysis = () => {
  const [query, setQuery] = useState('');
  const [platforms, setPlatforms] = useState(['twitter', 'linkedin']);
  const [selectedPlatform, setSelectedPlatform] = useState('all');
  const [timeframe, setTimeframe] = useState('week');
  const [includeHashtags, setIncludeHashtags] = useState(true);
  const [includeEngagement, setIncludeEngagement] = useState(true);
  const [includeSentiment, setIncludeSentiment] = useState(true);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  const timeframeOptions = [
    { value: 'day', label: 'Last 24 Hours' },
    { value: 'week', label: 'Last 7 Days' },
    { value: 'month', label: 'Last 30 Days' },
    { value: 'quarter', label: 'Last 3 Months' },
  ];

  const handleAnalyze = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      const data = await analyzeSocialMedia({
        query,
        platforms,
        timeframe,
        includeHashtags,
        includeEngagement,
        includeSentiment
      });

      
      setResults(data.data);
    } catch (err) {
      setError(err.message || 'An error occurred during analysis');
    } finally {
      setLoading(false);
    }
  };

  const renderHashtags = (hashtags) => (
    <div className="bg-white rounded-lg shadow p-4 mb-4">
      <h3 className="text-lg font-semibold mb-3 flex items-center">
        <HashtagIcon className="h-5 w-5 mr-2" />
        Top Hashtags
      </h3>
      <div className="flex flex-wrap gap-2">
        {hashtags.map((tag, index) => (
          <div key={index} className="bg-indigo-100 text-indigo-800 px-3 py-1 rounded-full text-sm">
            <span className="font-medium">{tag.hashtag}</span>
            <span className="text-indigo-600 ml-2">({tag.frequency})</span>
          </div>
        ))}
      </div>
    </div>
  );

  const renderEngagement = (engagement) => {
    const formatTimestamp = (timestamp) => {
      if (!timestamp) return 'No date';
      return new Date(timestamp).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    };
  
    // Get all content based on selected platform
    const getFilteredContent = () => {
      if (selectedPlatform === 'all') {
        return engagement.top_content.overall || [];
      } else {
        return engagement.top_content.by_platform[selectedPlatform] || [];
      }
    };
  
    const filteredContent = getFilteredContent();
    
    return (
      <div className="bg-white rounded-lg shadow p-4 mb-4">
        <h3 className="text-lg font-semibold mb-3 flex items-center">
          <ChartBarIcon className="h-5 w-5 mr-2" />
          Engagement Analysis
        </h3>
        
        {/* Platform Filter Tabs */}
        <div className="border-b border-gray-200 mb-4">
          <nav className="-mb-px flex space-x-8">
            <button
              className={`
                border-b-2 py-2 px-1 text-sm font-medium
                ${selectedPlatform === 'all'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
              `}
              onClick={() => setSelectedPlatform('all')}
            >
              All Posts
            </button>
            {engagement.top_content.by_platform && Object.keys(engagement.top_content.by_platform).map(platform => (
              <button
                key={platform}
                className={`
                  border-b-2 py-2 px-1 text-sm font-medium
                  ${selectedPlatform === platform
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                `}
                onClick={() => setSelectedPlatform(platform)}
              >
                {platform}
              </button>
            ))}
          </nav>
        </div>    
              <div className="space-y-3">
                {filteredContent.map((content, index) => (
                  <div key={index} className="border rounded p-3 hover:bg-gray-50 transition-colors">
                    <div className="flex justify-between items-start">
                      <div className="flex-1">
                        <p className="text-sm text-gray-800">
                          {content.summary}
                        </p>
                        <div className="flex items-center mt-1 space-x-2">
                          {content.timestamp && (
                            <span className="text-xs text-gray-500">
                              {formatTimestamp(content.timestamp)}
                            </span>
                          )}
                        </div>
                        <a
                          href={content.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-xs text-blue-600 hover:underline mt-1 block"
                        >
                          {content.url}
                        </a>
                      </div>
                      <span className="text-xs text-gray-500 capitalize ml-2">
                        {content.platform}
                      </span>
                    </div>
                    
                    <div className="mt-2 flex flex-wrap gap-2">
                      <div className="text-xs px-2 py-1 bg-gray-100 rounded">
                        Likes: {content.engagement_metrics.likes}
                      </div>
                      <div className="text-xs px-2 py-1 bg-gray-100 rounded">
                        Shares: {content.engagement_metrics.shares}
                      </div>
                      <div className="text-xs px-2 py-1 bg-gray-100 rounded">
                        Comments: {content.engagement_metrics.comments}
                      </div>
                      {content.total_engagement !== undefined && (
                        <div className="text-xs px-2 py-1 bg-gray-100 rounded">
                          Total Engagement: {content.total_engagement}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
    
              {/* Topic Performance Section */}
              {engagement.topic_performance && Object.keys(engagement.topic_performance).length > 0 && (
                <div className="mb-4">
                  <h4 className="font-medium mb-2">Topic Performance</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    {Object.entries(engagement.topic_performance)
                      .sort((a, b) => b[1].mentions - a[1].mentions)
                      .slice(0, 6)
                      .map(([topic, data]) => (
                        <div key={topic} className="border rounded p-3">
                          <div className="flex justify-between items-center mb-1">
                            <span className="font-medium">{topic}</span>
                            <span className="text-sm text-gray-500">
                              {data.mentions} mentions
                            </span>
                          </div>
                          <div className="text-xs text-gray-600">
                            <div>Avg Engagement: {data.avg_engagement.toFixed(2)}</div>
                            <div>Platforms: {data.platforms.join(", ")}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
    
              {/* Platform Metrics Section */}
              {engagement.by_platform && Object.keys(engagement.by_platform).length > 0 && (
                <div>
                  <h4 className="font-medium mb-2">Platform Performance</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    {Object.entries(engagement.by_platform).map(([platform, metrics]) => (
                      <div key={platform} className="border rounded p-3">
                        <div className="font-medium capitalize mb-2">{platform}</div>
                        <div className="text-sm space-y-1">
                          <div>Total Posts: {metrics.posts}</div>
                          <div>Avg Engagement: {metrics.avg_engagement?.toFixed(2) || '0.00'}</div>
                          <div className="text-xs mt-2">
                            Distribution:
                            <ul className="ml-2 mt-1">
                              {Object.entries(metrics.sentiment_distribution).map(([type, count]) => (
                                <li key={type}>
                                  {type}: {count}
                                </li>
                              ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          };
                
  

  const renderPostingTimes = (times) => (
    <div className="bg-white rounded-lg shadow p-4 mb-4">
      <h3 className="text-lg font-semibold mb-3 flex items-center">
        <ClockIcon className="h-5 w-5 mr-2" />
        Optimal Posting Times
      </h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h4 className="font-medium mb-2">Best Days</h4>
          {Object.entries(times.best_days).map(([day, stats]) => (
            <div key={day} className="flex justify-between text-sm mb-1">
              <span>{day}</span>
              <span className="text-gray-600">{stats.avg_engagement.toFixed(2)} avg. engagement</span>
            </div>
          ))}
        </div>
        <div>
          <h4 className="font-medium mb-2">Best Times</h4>
          {Object.entries(times.best_times).map(([time, stats]) => (
            <div key={time} className="flex justify-between text-sm mb-1">
              <span>{time}</span>
              <span className="text-gray-600">{stats.avg_engagement.toFixed(2)} avg. engagement</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <BaseLayout>
      <div className="flex h-screen overflow-hidden">
        {/* Left sidebar with form */}
        <div className="w-1/3 bg-white p-4 overflow-y-auto border-r border-gray-200">
          <form onSubmit={handleAnalyze} className="space-y-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Search Query
              </label>
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Enter topic or keyword"
                required
              />
            </div>
            {/* Add Timeframe Selector */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Time Range
              </label>
              <select
                value={timeframe}
                onChange={(e) => setTimeframe(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                         focus:ring-indigo-500 focus:border-indigo-500"
              >
                {timeframeOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Platforms
              </label>
              <div className="space-y-2">
                {['twitter', 'linkedin', 'medium', 'reddit'].map((platform) => (
                  <label key={platform} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={platforms.includes(platform)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setPlatforms([...platforms, platform]);
                        } else {
                          setPlatforms(platforms.filter(p => p !== platform));
                        }
                      }}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-700 capitalize">{platform}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Analysis Options
              </label>
              <div className="space-y-2">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={includeHashtags}
                    onChange={(e) => setIncludeHashtags(e.target.checked)}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <span className="ml-2 text-sm text-gray-700">Include Hashtag Analysis</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={includeEngagement}
                    onChange={(e) => setIncludeEngagement(e.target.checked)}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <span className="ml-2 text-sm text-gray-700">Include Engagement Analysis</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={includeSentiment}
                    onChange={(e) => setIncludeSentiment(e.target.checked)}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <span className="ml-2 text-sm text-gray-700">Include Sentiment Analysis</span>
                </label>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              {loading ? (
                <>
                  <ArrowPathIcon className="animate-spin h-5 w-5 mr-2" />
                  Analyzing...
                </>
              ) : (
                <>
                  <MagnifyingGlassIcon className="h-5 w-5 mr-2" />
                  Analyze
                </>
              )}
            </button>
          </form>
        </div>

        {/* Main content area with results */}
        <div className="flex-1 p-4 overflow-y-auto bg-gray-50">
          {error && (
            <div className="mb-4 bg-red-50 border-l-4 border-red-400 p-4">
              <div className="flex">
                <ExclamationCircleIcon className="h-5 w-5 text-red-400" />
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}


          {results && (
            <div className="space-y-4">
              {/* Summary Card */}
              <div className="bg-white rounded-lg shadow p-4">
                <h2 className="text-xl font-semibold mb-2">Analysis Results</h2>
                <div className="text-gray-600">
                  <p>
                    Analysis for "{results.query}" over the{' '}
                    {timeframe === 'day' ? 'last 24 hours' :
                    timeframe === 'week' ? 'last 7 days' :
                    timeframe === 'month' ? 'last 30 days' :
                    'last 3 months'}{' '}
                    across {results.platforms.join(", ")}
                  </p>
                  {results.date_range && (
                    <p className="text-sm mt-1">
                      Date Range: {new Date(results.date_range.start).toLocaleDateString()} to{' '}
                      {new Date(results.date_range.end).toLocaleDateString()}
                    </p>
                  )}
                  <p className="text-sm text-gray-500 mt-1">
                    Found {results.result_count} posts | Analyzed at: {new Date(results.analysis_timestamp).toLocaleString()}
                  </p>
                </div>
              </div>

              {/* Hashtag Analysis */}
              {results.hashtags && renderHashtags(results.hashtags)}

              {/* Engagement Analysis */}
              {results.engagement && renderEngagement(results.engagement)}

              {results.sentiment && (
              <div className="bg-white rounded-lg shadow p-4 mb-4">
                <h3 className="text-lg font-semibold mb-3 flex items-center">
                  <CheckCircleIcon className="h-5 w-5 mr-2" />
                  Sentiment Analysis
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* Overall Sentiment */}
                  <div className="border rounded-lg p-4">
                    <h4 className="font-medium mb-2">Overall Sentiment</h4>
                    <div className="flex items-center space-x-2">
                      <div
                        className={`w-3 h-3 rounded-full ${
                          results.sentiment.overall.sentiment === 'Very Positive' || results.sentiment.overall.sentiment === 'Positive'
                            ? 'bg-green-500'
                            : results.sentiment.overall.sentiment === 'Negative' || results.sentiment.overall.sentiment === 'Very Negative'
                            ? 'bg-red-500'
                            : 'bg-yellow-500'
                        }`}
                      />
                      <span className="capitalize">{results.sentiment.overall.sentiment}</span>
                      <span className="text-gray-500">
                        (Score: {results.sentiment.overall.score.toFixed(2)})
                      </span>
                    </div>
                    <div className="text-sm text-gray-500 mt-2">
                      Confidence: {(results.sentiment.overall.confidence * 100).toFixed(1)}%
                    </div>
                  </div>

                  {/* Sentiment Breakdown */}
                  <div className="border rounded-lg p-4">
                    <h4 className="font-medium mb-2">Sentiment Breakdown</h4>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span>Very Positive</span>
                        <span>{results.sentiment.breakdown.very_positive}</span>
                      </div>
                      <div className="flex justify-between">
                        <span>Positive</span>
                        <span>{results.sentiment.breakdown.positive}</span>
                      </div>
                      <div className="flex justify-between">
                        <span>Neutral</span>
                        <span>{results.sentiment.breakdown.neutral}</span>
                      </div>
                      <div className="flex justify-between">
                        <span>Negative</span>
                        <span>{results.sentiment.breakdown.negative}</span>
                      </div>
                      <div className="flex justify-between">
                        <span>Very Negative</span>
                        <span>{results.sentiment.breakdown.very_negative}</span>
                      </div>
                    </div>
                  </div>

                  {/* Notable Quotes */}
                  <div className="border rounded-lg p-4 md:col-span-2">
                    <h4 className="font-medium mb-2">Notable Quotes</h4>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <h5 className="text-sm font-medium text-green-600 mb-2">Positive Quotes</h5>
                        <div className="space-y-2">
                          {results.sentiment.notable_quotes.positive.map((quote, index) => (
                            <div key={index} className="text-sm border-l-4 border-green-200 pl-3 py-1">
                              <p className="text-gray-700">{quote.text}</p>
                              <div className="text-xs text-gray-500 mt-1">
                                <span>Platform: {quote.platform}</span> |
                                <span> Score: {quote.score.toFixed(2)}</span>
                                {quote.url && (
                                  <a href={quote.url} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="ml-2 text-blue-500 hover:underline">
                                    View Source
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div>
                        <h5 className="text-sm font-medium text-red-600 mb-2">Negative Quotes</h5>
                        <div className="space-y-2">
                          {results.sentiment.notable_quotes.negative.map((quote, index) => (
                            <div key={index} className="text-sm border-l-4 border-red-200 pl-3 py-1">
                              <p className="text-gray-700">{quote.text}</p>
                              <div className="text-xs text-gray-500 mt-1">
                                <span>Platform: {quote.platform}</span> |
                                <span> Score: {quote.score.toFixed(2)}</span>
                                {quote.url && (
                                  <a href={quote.url} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="ml-2 text-blue-500 hover:underline">
                                    View Source
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

              {/* Posting Times Analysis */}
              {results.posting_times && renderPostingTimes(results.posting_times)}

              {/* Platform-Specific Analysis */}
              {results.engagement?.by_platform && Object.keys(results.engagement.by_platform).length > 0 && (
                <div className="bg-white rounded-lg shadow p-4">
                  <h3 className="text-lg font-semibold mb-3">Platform Performance</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {Object.entries(results.engagement.by_platform).map(([platform, stats]) => (
                      <div key={platform} className="border rounded-lg p-4">
                        <h4 className="font-medium mb-2 capitalize">{platform}</h4>
                        <div className="space-y-1 text-sm">
                          <div className="flex justify-between">
                            <span>Posts</span>
                            <span>{stats.posts}</span>
                          </div>
                          <div className="flex justify-between">
                            <span>Avg Sentiment</span>
                            <span>{stats.avg_sentiment.toFixed(2)}</span>
                          </div>
                          <div className="mt-2">
                            <h5 className="text-xs font-medium mb-1">Sentiment Distribution</h5>
                            {Object.entries(stats.sentiment_distribution).map(([type, count]) => (
                              <div key={type} className="flex justify-between text-xs">
                                <span className="capitalize">{type.replace('_', ' ')}</span>
                                <span>{count}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

export default SocialMediaAnalysis;