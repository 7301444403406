import React, { useState } from 'react';
import { Moon, Sun } from 'lucide-react';
import { 
  Bars3Icon, 
  XMarkIcon,
  HomeIcon,
  ChatBubbleLeftRightIcon,
  NewspaperIcon,
  UserIcon,
  UserPlusIcon,
  CreditCardIcon,
  QuestionMarkCircleIcon,
  ViewfinderCircleIcon,
  DocumentIcon,
  MagnifyingGlassCircleIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import { useTheme } from './ThemeContexxt';

const BaseLayout = ({ children }) => {
  const { darkMode, toggleDarkMode } = useTheme();
  const [isNavExpanded, setIsNavExpanded] = useState(true);

  const navItems = [
    { name: 'Home', href: '/', icon: HomeIcon },
    { name: 'Prompt Library', href: '/prompt-template', icon: ChatBubbleLeftRightIcon },
    { name: 'Search', href: '/search', icon: MagnifyingGlassCircleIcon },
    { name: 'SocialAnalytics', href: '/social-analysis', icon: UserGroupIcon },
    { name: 'DocumentQA', href: '/document-qa', icon: DocumentIcon },
    { name: 'Notes', href: '/notes', icon: ViewfinderCircleIcon },
    { name: 'Profile', href: '/profile', icon: UserIcon },
    { name: 'Register', href: '/register', icon: UserPlusIcon },
    { name: 'Billing', href: '/billing', icon: CreditCardIcon },
    { name: 'About', href: '/about', icon: QuestionMarkCircleIcon },
    { name: 'Blog', href: '/blog', icon: NewspaperIcon },
  ];

  return (
    <div className={`min-h-screen ${darkMode ? 'dark' : ''}`}>
      {/* Side Navigation */}
      <aside 
        className={`fixed top-0 left-0 h-full ${
          darkMode ? 'bg-background-dark' : 'bg-white'
        } transition-all duration-300 ease-in-out ${
          isNavExpanded ? 'w-64' : 'w-16'
        } shadow-xl z-50 border-r ${
          darkMode ? 'border-primary-dark' : 'border-gray-200'
        }`}
      >
        {/* Logo and Toggle Area */}
        <div className="h-16 flex items-center justify-between px-4">
          {isNavExpanded && (
            <h1 className={`text-xl font-bold ${darkMode ? 'text-text-primary' : 'text-gray-900'}`}>
              SUMMIT53
            </h1>
          )}
          <button
            onClick={() => setIsNavExpanded(!isNavExpanded)}
            className={`${
              isNavExpanded ? 'ml-auto' : 'mx-auto'
            } p-2 rounded-full ${
              darkMode ? 'bg-primary text-accent-light' : 'bg-gray-200 text-gray-700'
            } hover:bg-opacity-80 transition-colors duration-300`}
          >
            {isNavExpanded ? (
              <XMarkIcon className="h-5 w-5" />
            ) : (
              <Bars3Icon className="h-5 w-5" />
            )}
          </button>
        </div>

        {/* Navigation Items */}
        <nav className="mt-4 px-2">
          {navItems.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={`flex items-center space-x-2 py-3 px-4 rounded-lg ${
                darkMode 
                  ? 'hover:bg-primary-dark text-text-primary' 
                  : 'hover:bg-gray-100 text-gray-900'
              } transition-colors duration-200 mb-1 group`}
            >
              <item.icon className="h-5 w-5 flex-shrink-0" />
              {isNavExpanded && (
                <span className="text-sm font-medium">{item.name}</span>
              )}
              {!isNavExpanded && (
                <div className="fixed left-16 ml-2 scale-0 group-hover:scale-100 transition-all duration-200 origin-left">
                  <div className={`${
                    darkMode ? 'bg-background text-text-primary' : 'bg-white text-gray-900'
                  } text-sm py-2 px-4 rounded-md shadow-lg whitespace-nowrap`}>
                    {item.name}
                  </div>
                </div>
              )}
            </a>
          ))}
        </nav>
      </aside>

      {/* Main Content Area */}
      <div className={`${isNavExpanded ? 'pl-64' : 'pl-16'} w-full min-h-screen flex flex-col`}>
        {/* Header */}
        <header className={`${darkMode ? 'bg-background' : 'bg-white'} shadow w-full`}>
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-end items-center">
            <button
              onClick={toggleDarkMode}
              className={`p-2 rounded-full ${
                darkMode ? 'bg-primary text-accent-light' : 'bg-gray-200 text-gray-700'
              } hover:bg-opacity-80 transition-colors duration-300`}
            >
              {darkMode ? <Sun size={24} /> : <Moon size={24} />}
            </button>
          </div>
        </header>

        {/* Main Content */}
        <main className={`flex-1 ${darkMode ? 'bg-background' : 'bg-gray-50'}`}>
          <div className="max-w-7xl mx-auto w-full py-6 px-4 sm:px-6 lg:px-8">
            {children}
          </div>
        </main>

        {/* Footer */}
        <footer className={`${
          darkMode ? 'bg-background-dark border-primary-dark' : 'bg-white border-gray-200'
        } border-t w-full`}>
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 text-center">
            <p className={`${darkMode ? 'text-text-secondary' : 'text-gray-500'}`}>
              © 2024 Summit53.io - Synthesizing and crafting ai
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default BaseLayout;