import React, { useState, useEffect } from 'react';
import { 
  PencilIcon, 
  TrashIcon, 
  TagIcon, 
  ViewfinderCircleIcon,
  XMarkIcon, 
  ChevronDownIcon,
  ChevronUpIcon,
  CheckIcon
} from '@heroicons/react/24/outline';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTheme } from './ThemeContexxt'; 
import BaseLayout from './BaseLayout';
import { notes } from '../services/api';

// Separate EditModal Component
const EditModal = ({ editingNote, setEditingNote, handleUpdate }) => {
  if (!editingNote) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl">
        <div className="p-4 border-b flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-900">Edit Note</h3>
          <button onClick={() => setEditingNote(null)} className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-900">Title</label>
            <input
              type="text"
              value={editingNote.title}
              onChange={(e) => setEditingNote({...editingNote, title: e.target.value})}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-900">Content</label>
            <textarea
              value={editingNote.content}
              onChange={(e) => setEditingNote({...editingNote, content: e.target.value})}
              rows={4}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-900">Tags</label>
            <input
              type="text"
              value={editingNote.tags.join(', ')}
              onChange={(e) => setEditingNote({
                ...editingNote, 
                tags: e.target.value.split(',').map(tag => tag.trim()).filter(Boolean)
              })}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Comma-separated tags"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setEditingNote(null)}
              className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleUpdate}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Separate ExpandedNoteView Component
const ExpandedNoteView = ({ 
  note, 
  isExpanded, 
  onClose,  
  onDelete,
  expandedMessages,
  toggleMessageExpand,
  handleUpdate  
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedNote, setEditedNote] = useState(note);
  
  if (!isExpanded) return null;

  const handleSave = async () => {
    await handleUpdate(editedNote);
    setIsEditing(false);
  };
  return ( 
  <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
        {/* Header */}
        <div className="p-6 border-b flex justify-between items-center bg-gray-50">
          {isEditing ? (
            <input
              type="text"
              value={editedNote.title}
              onChange={(e) => setEditedNote({ ...editedNote, title: e.target.value })}
              className="text-2xl font-semibold text-gray-900 w-full max-w-[80%] rounded-md border-gray-300"
            />
          ) : (
            <h2 className="text-2xl font-semibold text-gray-900 line-clamp-1">{note.title}</h2>
          )}
          <div className="flex items-center space-x-3">
            {isEditing ? (
              <>
                <button
                  onClick={handleSave}
                  className="p-2 text-gray-500 hover:text-green-600 transition-colors rounded-full hover:bg-gray-100"
                  title="Save changes"
                >
                  <CheckIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={() => {
                    setIsEditing(false);
                    setEditedNote(note);
                  }}
                  className="p-2 text-gray-500 hover:text-red-600 transition-colors rounded-full hover:bg-gray-100"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => setIsEditing(true)}
                  className="p-2 text-gray-500 hover:text-indigo-600 transition-colors rounded-full hover:bg-gray-100"
                  title="Edit note"
                >
                  <PencilIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={() => onDelete(note.id)}
                  className="p-2 text-gray-500 hover:text-red-600 transition-colors rounded-full hover:bg-gray-100"
                  title="Delete note"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={onClose}
                  className="p-2 text-gray-500 hover:text-gray-700 transition-colors rounded-full hover:bg-gray-100"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </>
            )}
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
        {/* Main Content */}
        <div className="prose dark:prose-invert max-w-none">
            {isEditing ? (
              <textarea
                value={editedNote.content}
                onChange={(e) => setEditedNote({ ...editedNote, content: e.target.value })}
                rows={8}
                className="w-full rounded-md border-gray-300"
                placeholder="Note content... (Markdown supported)"
              />
            ) : (
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  code({node, inline, className, children, ...props}) {
                    return !inline ? (
                      <div className="relative my-4 rounded-lg bg-gray-100 dark:bg-gray-800">
                        <pre className="overflow-x-auto p-4 text-sm">
                          <code className={`${className} text-sm`} {...props}>
                            {children}
                          </code>
                        </pre>
                      </div>
                    ) : (
                      <code className="rounded bg-gray-100 dark:bg-gray-800 px-1.5 py-0.5 text-sm" {...props}>
                        {children}
                      </code>
                    );
                  }
                }}
              >
                {note.content}
              </ReactMarkdown>
            )}
          </div>
          {/* Original Message Section */}
          {note.original_message && (
            <div className="mt-8 bg-gray-50 rounded-lg p-4">
              <button
                onClick={() => toggleMessageExpand(note.id)}
                className="flex items-center space-x-2 text-sm text-gray-700 hover:text-gray-900 transition-colors"
              >
                {expandedMessages.has(note.id) ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
                <span className="font-medium">Original Message</span>
                <span className="px-2 py-0.5 text-xs bg-gray-200 rounded-full">
                  {note.original_message.role}
                </span>
              </button>

              <div className={`overflow-hidden transition-all duration-200 ${
                expandedMessages.has(note.id) ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
              }`}>
                <div className="mt-4 pl-6 border-l-2 border-indigo-200">
                  <p className="text-gray-600">
                    {note.original_message.content}
                  </p>
                  {note.original_message.timestamp && (
                    <p className="text-sm text-gray-500 mt-2">
                      {new Date(note.original_message.timestamp).toLocaleString()}
                    </p>
                  )}
                  {note.original_message.metadata && 
                   Object.keys(note.original_message.metadata).length > 0 && (
                    <div className="mt-4">
                      <details className="text-sm">
                        <summary className="font-medium text-gray-700 cursor-pointer hover:text-indigo-600">
                          Metadata
                        </summary>
                        <pre className="mt-2 p-4 bg-gray-100 rounded-lg overflow-x-auto text-sm">
                          {JSON.stringify(note.original_message.metadata, null, 2)}
                        </pre>
                      </details>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Tags Section */}
          <div className="mt-6">
            <h3 className="text-sm font-medium text-gray-700 mb-2">Tags</h3>
            {isEditing ? (
              <input
                type="text"
                value={editedNote.tags.join(', ')}
                onChange={(e) => setEditedNote({
                  ...editedNote,
                  tags: e.target.value.split(',').map(tag => tag.trim()).filter(Boolean)
                })}
                className="w-full rounded-md border-gray-300"
                placeholder="Enter tags separated by commas"
              />
            ) : (
              <div className="flex flex-wrap gap-2">
                {note.tags.map(tag => (
                  <span
                    key={tag}
                    className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-indigo-50 text-indigo-700"
                  >
                    <TagIcon className="h-4 w-4 mr-1" />
                    {tag}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-4 border-t bg-gray-50">
          <p className="text-sm text-gray-500">
            Last updated: {new Date(note.updated_at).toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
};


// Main NotesPage Component
const NotesPage = () => {
  const [notesList, setNotesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [editingNote, setEditingNote] = useState(null);
  const [allTags, setAllTags] = useState([]);

  const [expandedMessages, setExpandedMessages] = useState(new Set());
  const [expandedNote, setExpandedNote] = useState(null);


  // Add this handler
  const toggleMessageExpand = (noteId) => {
    setExpandedMessages(prev => {
      const newSet = new Set(prev);
      if (newSet.has(noteId)) {
        newSet.delete(noteId);
      } else {
        newSet.add(noteId);
      }
      return newSet;
    });
  };
  // Fetch notes on component mount
  useEffect(() => {
    fetchNotes();
  }, []);

  // Extract unique tags from notes
  useEffect(() => {
    console.log('Notes list updated:', notesList);
    const tags = [...new Set(notesList.flatMap(note => note.tags))];
    console.log('Extracted tags:', tags);
    setAllTags(tags);
  }, [notesList]);

  const fetchNotes = async () => {
    try {
      setLoading(true);
      const response = await notes.list({
        search: searchTerm,
        tags: selectedTags
      });
      console.log('Fetched notes:', response); // Debug logging
      // The response is already an array, so we don't need to access .data
      setNotesList(response || []);  // Remove .data access
      setError('');
    } catch (err) {
      setError('Failed to fetch notes: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (noteId) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        await notes.delete(noteId);
        setNotesList(notesList.filter(note => note.id !== noteId));
      } catch (err) {
        setError('Failed to delete note: ' + err.message);
      }
    }
  };

  const handleEdit = (note) => {
    setEditingNote({
      ...note,
      tags: note.tags || [], // Ensure tags is always an array
      original_message: note.original_message || {
        role: 'user',
        content: '',
        timestamp: new Date().toISOString()
      }
    });
  };

  const handleUpdate = async (updatedNote) => {
    try {
      console.log('Updating note:', updatedNote); // Keep debug logging
  
      // Preserve original message handling
      const originalMessage = updatedNote.original_message ? {
        role: updatedNote.original_message.role,
        content: updatedNote.original_message.content,
        timestamp: updatedNote.original_message.timestamp,
        metadata: updatedNote.original_message.metadata || {}
      } : null;
  
      // Construct the update payload
      const updatePayload = {
        id: updatedNote.id,
        title: updatedNote.title,
        content: updatedNote.content,
        tags: updatedNote.tags,
        created_at: updatedNote.created_at,
        updated_at: new Date().toISOString(),
        original_message: originalMessage
      };
  
      // Make the API call
      const result = await notes.update(updatedNote.id, updatePayload);
      
      // Update both the notes list and the expanded note view
      setNotesList(notesList.map(note => 
        note.id === result.id ? result : note
      ));
      setExpandedNote(result); // Update the expanded view with the new data
      
    } catch (err) {
      console.error('Update error:', err);
      setError('Failed to update note: ' + err.message);
    }
  };

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(t => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };
  return (
    <BaseLayout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">My Notes</h1>
          
          {/* Search and Filter */}
          <div className="flex flex-col sm:flex-row gap-4 mb-6">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search notes..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              />
              <ViewfinderCircleIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
            
            <button
              onClick={() => fetchNotes()}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Search
            </button>
          </div>
  
          {/* Tags Filter */}
          {allTags.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-4">
              {allTags.map(tag => (
                <button
                  key={tag}
                  onClick={() => handleTagClick(tag)}
                  className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                    selectedTags.includes(tag)
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  <TagIcon className="h-4 w-4 mr-1" />
                  {tag}
                </button>
              ))}
            </div>
          )}
        </div>
  
        {/* Error Message */}
        {error && (
          <div className="mb-4 bg-red-50 border-l-4 border-red-400 p-4">
            <div className="flex">
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}
  
        {/* Notes Display */}
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
          </div>
        ) : notesList.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500 text-lg">No notes found</p>
            {searchTerm || selectedTags.length > 0 ? (
              <p className="text-gray-400 mt-2">Try adjusting your search or filters</p>
            ) : null}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {notesList.map(note => (
              <div 
                key={note.id} 
                className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow"
              >
                {/* Title and Actions Section */}
                <div className="flex justify-between items-start mb-3">
                  <h2 className="text-xl font-semibold text-gray-900 break-words max-w-[80%]">
                    {note.title}
                  </h2>
                  <div className="flex space-x-2 flex-shrink-0">
                    <button
                      onClick={() => handleEdit(note)}
                      className="text-gray-400 hover:text-indigo-600 transition-colors"
                      title="Edit note"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(note.id)}
                      className="text-gray-400 hover:text-red-600 transition-colors"
                      title="Delete note"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => setExpandedNote(note)}
                      className="text-gray-400 hover:text-indigo-600 transition-colors"
                      title="View full note"
                    >
                      <ViewfinderCircleIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                {/* Content Section */}
                <div className="mb-3">
                  <div className="prose dark:prose-invert prose-sm max-w-none line-clamp-3 hover:line-clamp-none">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        code({node, inline, className, children, ...props}) {
                          return inline ? (
                            <code className="rounded bg-gray-100 dark:bg-gray-800 px-1.5 py-0.5 text-sm" {...props}>
                              {children}
                            </code>
                          ) : (
                            <div className="my-2 rounded-lg bg-gray-100 dark:bg-gray-800">
                              <pre className="overflow-x-auto p-2 text-sm">
                                <code className={`${className} text-sm`} {...props}>
                                  {children}
                                </code>
                              </pre>
                            </div>
                          );
                        }
                      }}
                    >
                      {note.content}
                    </ReactMarkdown>
                  </div>
                </div>

                {/* Original Message Section */}
                {note.original_message && (
                  <div className="mb-3">
                    <button
                      onClick={() => toggleMessageExpand(note.id)}
                      className="flex items-center space-x-2 text-sm text-gray-500 hover:text-gray-700 transition-colors"
                    >
                      {expandedMessages.has(note.id) ? (
                        <ChevronUpIcon className="h-4 w-4" />
                      ) : (
                        <ChevronDownIcon className="h-4 w-4" />
                      )}
                      <span>Original Message</span>
                      <span className="px-2 py-0.5 text-xs bg-gray-200 rounded-full">
                        {note.original_message.role}
                      </span>
                    </button>
                    
                    {expandedMessages.has(note.id) && (
                      <div className="mt-2 pl-6 border-l-2 border-gray-200">
                        <p className="text-sm text-gray-600">
                          {note.original_message.content}
                        </p>
                        {note.original_message.timestamp && (
                          <p className="text-xs text-gray-500 mt-1">
                            {new Date(note.original_message.timestamp).toLocaleString()}
                          </p>
                        )}
                        {note.original_message.metadata && 
                         Object.keys(note.original_message.metadata).length > 0 && (
                          <div className="mt-2">
                            <details className="text-xs">
                              <summary className="font-medium text-gray-700 cursor-pointer">
                                Metadata
                              </summary>
                              <pre className="mt-1 p-2 bg-gray-50 rounded overflow-x-auto">
                                {JSON.stringify(note.original_message.metadata, null, 2)}
                              </pre>
                            </details>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {/* Tags Section */}
                {note.tags && note.tags.length > 0 && (
                  <div className="flex flex-wrap gap-1.5 mb-3">
                    {note.tags.map(tag => (
                      <span
                        key={tag}
                        className="inline-flex items-center px-2 py-0.5 text-xs font-medium bg-indigo-50 text-indigo-700 rounded-full"
                      >
                        <TagIcon className="h-3 w-3 mr-1" />
                        {tag}
                      </span>
                    ))}
                  </div>
                )}

                {/* Footer Section */}
                <div className="text-xs text-gray-400 border-t pt-2">
                  Last updated: {new Date(note.updated_at).toLocaleString(undefined, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Modals */}
        <EditModal 
          editingNote={editingNote}
          setEditingNote={setEditingNote}
          handleUpdate={handleUpdate}
        />
        
        {expandedNote && (
          <ExpandedNoteView
            note={expandedNote}
            isExpanded={true}
            onClose={() => setExpandedNote(null)}
            onDelete={handleDelete}
            expandedMessages={expandedMessages}
            toggleMessageExpand={toggleMessageExpand}
            handleUpdate={handleUpdate}  // Pass handleUpdate directly
          />
        )}
      </div>
    </BaseLayout>
  );
};

export default NotesPage;