import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api';
axios.defaults.baseURL = API_BASE_URL;

const TIMEOUT_MS = 30000; // 10 seconds timeout
const MAX_RETRIES = 3;


// Create axios instance
export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 120000,
});

// Add request interceptor for token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Helper function to handle Axios errors
const handleAxiosError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error('Error data:', error.response.data);
    console.error('Error status:', error.response.status);
    console.error('Error headers:', error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.error('Error request:', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error message:', error.message);
  }
};

// Utility functions
const validateInputs = (input, visa, language) => {
    if (!input.trim()) throw new Error('Input is required');
    if (!visa.trim()) throw new Error('Visa type is required');
    if (!language.trim()) throw new Error('Language is required');
  };
  
  // Custom error types
  class APIError extends Error {
    constructor(message, status) {
      super(message);
      this.name = 'APIError';
      this.status = status;
    }
  }
  
  class NetworkError extends Error {
    constructor(message) {
      super(message);
      this.name = 'NetworkError';
    }
  }

  class TimeoutError extends Error {
    constructor(message) {
      super(message);
      this.name = 'TimeoutError';
    }
  }

  export const fetchPromptTemplate = async (payload) => {
    try {
      let sessionId = localStorage.getItem('chatSessionId');
      if (!payload.payload.data.variables.session_id) {
        payload.payload.data.variables.session_id = sessionId;
      }
  
      console.log('Sending payload:', JSON.stringify(payload, null, 2));
  
      const response = await axios.post('/prompt-template/', payload);
  
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);
      console.log('Response data:', response.data);
  
      // Log token usage if it exists, but don't modify the response
      if (response.data?.token_usage) {
        console.log('Token usage:', response.data.token_usage);
      }
  
      if (response.data.session_id) {
        localStorage.setItem('chatSessionId', response.data.session_id);
      }
  
      return response.data;  // Keep original return behavior
    } catch (error) {
      console.error("Error fetching prompt template:", error);
      handleAxiosError(error);
      throw error;
    }
  };


  export const toggleTemplateFavorite = async (templateName) => {
    try {
      const token = localStorage.getItem('token');
      console.log('Auth token:', token); // Debug log
      
      if (!token) {
        throw new Error('No authentication token found');
      }
  
      const response = await api.post(
        `/prompt-templates/${templateName}/favorite/`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error toggling favorite:', error);
      throw error;
    }
  };

  export const fetchFavoriteTemplates = async () => {
    try {
      const response = await api.get('/prompt-templates/user/favorites/');
      console.log('API Response:', response.data); // Debug log
      return response.data.templates || []; // Return just the templates array
    } catch (error) {
      console.error('Error fetching favorites:', error);
      throw error;
    }
  };

export const clearChatSession = () => {
  localStorage.removeItem('chatSessionId');
};
  
  // Usage example:
  // try {
  //   const response = await fetchPromptTemplate(
  //     "visa",
  //     "What are the requirements for a work visa?",
  //     {
  //       visa_category: "work",
  //       country: "USA",
  //       language: "English"
  //     }
  //   );
  //   console.log(response);
  // } catch (error) {
  //   console.error("Error:", error.message);
  // }
  // Add to api.js
// Update the api.js function to handle the new response format:
export const searchPromptTemplates = async (query, useCase = null) => {
  try {
    const params = new URLSearchParams();
    if (query) params.append('q', query);
    if (useCase) params.append('use_case', useCase);
    
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No authentication token found');
      throw new Error('Authentication required');
    }

    const response = await api.get(`/prompt-templates/search?${params.toString()}`);
    return response.data.templates;
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      console.error('Authentication error:', error);
      // Optionally redirect to login
      // window.location.href = '/login';
    }
    console.error("Error searching prompt templates:", error);
    throw error;
  }
};

  export const fetchAllUseCases = async () => {
    try {
      const response = await axios.get('/use-cases/');
      return response.data.use_cases;
    } catch (error) {
      console.error("Error fetching all use cases:", error);
      handleAxiosError(error);
      throw error;
    }
  };
  
  export const fetchLLMs = async () => {
    try {
      const response = await axios.get('/llms/');
      console.log('Fetched LLMs:', response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching LLMs:", error);
      handleAxiosError(error);
      throw new Error('Failed to fetch LLM list');
    }
  };
  
  export const fetchTemplateNamesByUseCase = async (useCase) => {
    try {
      // If no use case is provided, fetch all templates
      const endpoint = useCase 
        ? `/templates-by-use-case/${encodeURIComponent(useCase)}/`
        : '/prompt-template-names/'; // This should be your endpoint for all templates
  
      const response = await api.get(endpoint);
      return response.data.templates || response.data.names || [];
    } catch (error) {
      console.error("Error fetching template names by use case:", error);
      throw error;
    }
  };

  export const fetchUseCases = async (templateName) => {
    try {
      const response = await fetch(`${API_BASE_URL}/prompt-template-use-cases/${templateName}/`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.use_cases; // This will be an array with a single item
    } catch (error) {
      console.error("Error fetching use case:", error);
      throw error;
    }
  };

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    console.error("API Error:", response.status, errorData);
    throw new Error(`API request failed with status ${response.status}`);
  }
  return response.json();
};

export const fetchTemplateDetails = async (templateName) => {
    try {
      const encodedTemplateName = encodeURIComponent(templateName);
      const response = await axios.get(`/prompt-templates/${encodedTemplateName}/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching template details:", error);
      handleAxiosError(error);
      throw error;
    }
  };
  

export const fetchPromptTemplateNames = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/prompt-template-names/`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.names;
    } catch (error) {
      console.error("Error fetching prompt template names:", error);
      throw error;
    }
  };

  

export const fetchRedditData = async (query, timeFilter, sort) => {
    console.log(`Fetching Reddit data with params: query=${query}, timeFilter=${timeFilter}, sort=${sort}`);
    try {
      const url = `http://localhost:8000/api/reddit-scraper/query=${encodeURIComponent(query)}&time_filter=${timeFilter}&sort=${sort}`;
      console.log('Request URL:', url);
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      
      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers.entries()));
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response body:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
      }
      
      const contentType = response.headers.get("content-type");
      console.log('Response content-type:', contentType);
  
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        console.log('Parsed JSON data:', JSON.stringify(data, null, 2));
        return data;
      } else {
        const text = await response.text();
        console.error('Unexpected response content:', text);
        throw new Error(`Expected JSON, got ${contentType}: ${text}`);
      }
    } catch (error) {
      console.error('Error scraping Reddit posts:', error);
      throw error;
    }
  };

  // Reddit API calls
// Reddit API endpoints
export const fetchRedditPosts = async () => {
  try {
    const response = await api.get('/reddit/posts/');
    return response.data;
  } catch (error) {
    console.error('Error fetching Reddit posts:', error);
    if (error.response?.status === 401) {
      throw new Error('Please log in to view Reddit posts');
    }
    throw new Error(error.response?.data?.message || 'Failed to fetch Reddit posts');
  }
};

export const fetchRedditSearch = async (query, timeFilter = 'month', sort = 'relevance') => {
  try {
    const response = await api.get('/reddit/scraper/', {
      params: {
        query,
        time_filter: timeFilter,
        sort
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error searching Reddit:', error);
    throw new Error(error.response?.data?.message || 'Failed to search Reddit');
  }
};

export const fetchPostComments = async (postId) => {
  try {
    const response = await api.get(`/reddit/${postId}/comments/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching comments:', error);
    throw new Error(error.response?.data?.message || 'Failed to fetch comments');
  }
};

  export const fetchSocialData = async ({ comment, company, language }) => {
    console.log('Fetching social data with params:', { comment, company, language });
    try {
      const response = await axios.post('/social/', {
        post: comment,
        company: company,
        language: language
      });
      console.log('Received data:', response.data);
      return response.data.text || response.data.content || "Default message if no text found";
    } catch (error) {
      console.error('Error fetching social data:', error);
      handleAxiosError(error);
      throw new Error(`Failed to fetch social data: ${error.message}`);
    }
  };


  export const fetchTokenUsage = async (startDate = null, endDate = null) => {
    try {
      const params = new URLSearchParams();
      if (startDate) params.append('start_date', startDate);
      if (endDate) params.append('end_date', endDate);
      
      const response = await axios.get(`/token-usage/?${params.toString()}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching token usage:', error);
      handleAxiosError(error);
      throw new Error('Failed to fetch token usage');
    }
  };
  
  const handleTokenUsageResponse = (response) => {
    if (response.data?.token_usage) {
      console.log('Token usage:', response.data.token_usage);
      // You can dispatch this to your state management if needed
      return {
        ...response.data,
        token_usage: response.data.token_usage
      };
    }
    return response.data;
  };
// services/api.js

export const fetchImmigrationConsultantData = async (input, visa, language, retries = 0) => {
    try {
      validateInputs(input, visa, language);
      const response = await axios.post('/immigration-consultant', { input, visa, language }, {
        timeout: TIMEOUT_MS
      });
      const data = response.data;
      if (data && data.content && data.response_metadata) {
        return {
          content: data.content,
          tokenUsage: data.response_metadata.token_usage,
          modelName: data.response_metadata.model_name
        };
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new TimeoutError('Request timed out');
      }
      if (error.response && error.response.status >= 500 && retries < MAX_RETRIES) {
        console.log(`Retrying request (${retries + 1}/${MAX_RETRIES})...`);
        return fetchImmigrationConsultantData(input, visa, language, retries + 1);
      }
      handleAxiosError(error);
      throw error;
    }
  };

  // RAG Fetch
  export const fetchRAGResponse = async (documentStoreId, question, sessionId = null, llmName = null) => {
    try {
      const response = await api.post('/rag/response/', {
        document_store_id: documentStoreId,
        question,
        session_id: sessionId,
        llm_name: llmName
      });
  
      console.log('RAG Response status:', response.status);
      console.log('RAG Response headers:', response.headers);
      console.log('RAG Response data:', response.data);
  
      // If token usage exists in response, log it
      if (response.data?.token_usage) {
        console.log('RAG Response token usage:', response.data.token_usage);
      }
  
      // Return the complete response.data, which may include token_usage
      // This maintains existing functionality while allowing access to token_usage if present
      return response.data;
  
    } catch (error) {
      console.error("Error fetching RAG response:", error);
      if (error.response?.data?.detail) {
        throw new Error(`API Error: ${JSON.stringify(error.response.data.detail)}`);
      }
      throw error;
    }
  };

  // api.js - Add these functions

export const fetchDocumentStores = async () => {
  try {
    const response = await api.get('/rag/document-stores/');
    if (response.data.status === 'success') {
      return response.data.document_stores;
    }
    throw new Error(response.data.message || 'Failed to fetch document stores');
  } catch (error) {
    console.error("Error fetching document stores:", error);
    handleAxiosError(error);
    throw new Error('Failed to fetch document stores');
  }
};

export const createDocumentStore = async (name, filePath) => {
  try {
    const response = await axios.post('/rag/document-stores/', {
      name,
      file_path: filePath
    });
    if (response.data.status === 'success') {
      return response.data.document_store;
    }
    throw new Error(response.data.message || 'Failed to create document store');
  } catch (error) {
    console.error("Error creating document store:", error);
    handleAxiosError(error);
    throw new Error('Failed to create document store');
  }
};

export const notes = {
  create: async (noteData) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      // Flatten the payload structure to match API expectations
      const response = await api.post('/notes/', {
        title: noteData.title,
        content: noteData.content,
        tags: noteData.tags,
        original_message: {
          role: noteData.original_message.role,
          content: noteData.original_message.content,
          timestamp: noteData.original_message.timestamp,
          metadata: noteData.original_message.metadata || {}
        }
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      return response.data;
    } catch (error) {
      console.error('Error creating note:', error);
      handleAxiosError(error);
      throw error;
    }
  },
  
  list: async ({ search, tags, archived } = {}) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      const params = new URLSearchParams();
      if (search) params.append('search', search);
      if (tags) tags.forEach(tag => params.append('tags', tag));
      if (archived !== undefined) params.append('archived', archived);

      const response = await api.get(`/notes/?${params.toString()}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const notes = response.data.map(note => ({
        ...note,
        original_message: note.original_message || null
      }));

      return notes;
    } catch (error) {
      console.error('Error fetching notes:', error);
      if (error.response?.status === 500) {
        console.error('Server error details:', error.response.data);
      }
      throw new Error(error.response?.data?.message || 'Failed to fetch notes');
    }
  },

  get: async (noteId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      const response = await api.get(`/notes/${noteId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching note:', error);
      throw new Error(error.response?.data?.message || 'Failed to fetch note');
    }
  },

  update: async (noteId, noteData) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }
      
      // Ensure original_message is a plain object
    const originalMessage = noteData.original_message ? {
      role: noteData.original_message.role,
      content: noteData.original_message.content,
      timestamp: noteData.original_message.timestamp,
      metadata: noteData.original_message.metadata || {}
    } : null;
      const response = await api.put(`/notes/${noteId}`, {
        data: {  // Wrap in data object
          title: noteData.title,
          content: noteData.content,
          tags: noteData.tags,
          original_message: noteData.original_message || {
            role: 'user',
            content: noteData.content,
            timestamp: new Date().toISOString(),
            metadata: {}
          }
        }
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating note:', error);
      throw new Error(error.response?.data?.message || 'Failed to update note');
    }
  },

  delete: async (noteId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      await api.delete(`/notes/${noteId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error deleting note:', error);
      throw new Error(error.response?.data?.message || 'Failed to delete note');
    }
  },

  archive: async (noteId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      const response = await api.post(`/notes/${noteId}/archive/`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error archiving note:', error);
      throw new Error(error.response?.data?.message || 'Failed to archive note');
    }
  }
};

export const performSearch = async ({
  query,
  searchType,
  includeDomains,
  excludeDomains,
  selectedLLM
}) => {
  try {
    const response = await api.post('/search/search', {
      query,
      search_type: searchType,
      include_domains: includeDomains ? includeDomains.split(',').map(d => d.trim()) : [],
      exclude_domains: excludeDomains ? excludeDomains.split(',').map(d => d.trim()) : [],
      model_name: selectedLLM
    });

    // axios automatically throws on non-200 responses
    // and automatically parses JSON
    return response.data;
  } catch (error) {
    console.error("Search error:", error);
    throw new Error(error.response?.data?.message || 'Search failed');
  }
};

export const analyzeSocialMedia = async ({
  query,
  platforms,
  timeframe,
  includeHashtags,
  includeEngagement,
  includeSentiment
 }) => {
  try {
    const response = await api.post('/search/social/analyze', {
      query,
      platforms,
      timeframe,
      include_hashtags: includeHashtags,
      include_engagement: includeEngagement,
      include_sentiment: includeSentiment
    });
 
    if (response.data.status === 'error') {
      throw new Error(response.data.message);
    }
 
    return response.data;
  } catch (error) {
    console.error("Social media analysis error:", error);
    throw error;
  }
 };