import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from '../pages/ThemeContexxt';
import { 
  MagnifyingGlassIcon,
  FunnelIcon,
  Squares2X2Icon,
  ListBulletIcon 
} from '@heroicons/react/24/outline';
import { 
  fetchTemplateNamesByUseCase, 
  fetchAllUseCases,
  fetchTemplateDetails 
} from '../services/api';

const TemplateLibrary = () => {
  const { darkMode } = useTheme();
  const [templates, setTemplates] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUseCase, setSelectedUseCase] = useState('');
  const [useCases, setUseCases] = useState([]);
  const [viewMode, setViewMode] = useState('grid');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        // First load all templates without any filters
        const templatesResponse = await fetchTemplateNamesByUseCase('');
        // Then load all use cases
        const useCasesData = await fetchAllUseCases();
        
        // Get details for each template
        const templatesWithDetails = await Promise.all(
          templatesResponse.map(async (templateName) => {
            try {
              const details = await fetchTemplateDetails(templateName);
              return {
                name: templateName,
                ...details
              };
            } catch (err) {
              console.error(`Error fetching details for ${templateName}:`, err);
              return {
                name: templateName,
                description: '',
                use_case: '',
                input_variables: []
              };
            }
          })
        );

        setTemplates(templatesWithDetails);
        setUseCases(useCasesData);
      } catch (err) {
        setError('Failed to load templates');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const filteredTemplates = useMemo(() => {
    return templates.filter(template => {
      const matchesSearch = !searchQuery || 
        template.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (template.description && template.description.toLowerCase().includes(searchQuery.toLowerCase()));
      
      const matchesUseCase = !selectedUseCase || template.use_case === selectedUseCase;
      
      return matchesSearch && matchesUseCase;
    });
  }, [templates, searchQuery, selectedUseCase]);

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          Template Library
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setViewMode('grid')}
            className={`p-2 rounded-md ${
              viewMode === 'grid' 
                ? 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900 dark:text-indigo-200' 
                : 'text-gray-600 dark:text-gray-400'
            }`}
          >
            <Squares2X2Icon className="h-5 w-5" />
          </button>
          <button
            onClick={() => setViewMode('list')}
            className={`p-2 rounded-md ${
              viewMode === 'list' 
                ? 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900 dark:text-indigo-200' 
                : 'text-gray-600 dark:text-gray-400'
            }`}
          >
            <ListBulletIcon className="h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Search and Filters */}
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search templates..."
            className="w-full pl-10 pr-4 py-2 border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <select
          value={selectedUseCase}
          onChange={(e) => setSelectedUseCase(e.target.value)}
          className="px-4 py-2 border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
        >
          <option value="">All Use Cases</option>
          {useCases.map(useCase => (
            <option key={useCase} value={useCase}>{useCase}</option>
          ))}
        </select>
      </div>

      {/* Templates Grid/List */}
      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin h-8 w-8 mx-auto border-4 border-indigo-500 border-t-transparent rounded-full" />
        </div>
      ) : error ? (
        <div className="text-center py-12 text-red-600 dark:text-red-400">
          {error}
        </div>
      ) : (
        <div className={viewMode === 'grid' ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6' : 'space-y-4'}>
          {filteredTemplates.map(template => (
            <div
              key={template.name}
              className={`
                bg-white dark:bg-gray-800 rounded-lg shadow-md 
                ${viewMode === 'grid' ? 'p-6' : 'p-4'}
                border border-gray-200 dark:border-gray-700
              `}
            >
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                {template.name}
              </h3>
              <div className="text-sm text-indigo-600 dark:text-indigo-400 mb-2">
                {template.use_case}
              </div>
              {template.description && (
                <p className="text-gray-600 dark:text-gray-300 text-sm mb-4">
                  {template.description}
                </p>
              )}
              {template.input_variables?.variables && (
                <div className="flex flex-wrap gap-2">
                  {template.input_variables.variables.map(variable => (
                    <span
                      key={variable.name}
                      className="px-2 py-1 text-xs rounded-full bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300"
                    >
                      {variable.name}
                      {variable.required && '*'}
                    </span>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TemplateLibrary;