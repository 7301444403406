// Filename - App.js

import React from "react";
//import NavBar from "./components/NavBar";
import {
	BrowserRouter as Router,
	Routes,
	Route,
  Navigate,
} from "react-router-dom";
//import SignUp from "./pages/signup";
import { ThemeProvider } from "./pages/ThemeContexxt";
import Blog from "./pages/blog";
import PromptTemplatePage from "./pages/PromptTemplate";
import { AuthProvider, useAuth } from './pages/AuthContext';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import BillingPage from './pages/billing';
import NotesPage from "./pages/NotesManagement";
import DocumentQA from "./pages/document-qa";
import Search from "./pages/SearchPage";
import SocialMediaAnalysis from "./pages/SocialMediaAnalysis";
import ResetPassword from "./pages/ResetPassword";
import HomeDashboard from "./pages/HomeDashboard";
import AboutPage from "./pages/AboutPage";
import axios from 'axios';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!user) {
    return <Navigate to="/login" />;
  }
  
  return children;
};

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/blog" element={<Blog />} />
            <Route path="/prompt-template" element={<ProtectedRoute><PromptTemplatePage /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/billing" element={<ProtectedRoute><BillingPage /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/document-qa" element={<ProtectedRoute><DocumentQA /></ProtectedRoute>} />
            <Route path="/notes" element={<ProtectedRoute><NotesPage /></ProtectedRoute>} />
            <Route path="/search" element={<ProtectedRoute><Search /></ProtectedRoute>} />
            <Route path="/social-analysis" element={<ProtectedRoute><SocialMediaAnalysis /></ProtectedRoute>} />
            <Route path="/" element={<HomeDashboard />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
