// src/pages/About.js
import BaseLayout from "./BaseLayout";
function Blog() {
    return (
      <BaseLayout>
      <div>
        <h1>Blog</h1>
        <p>This is the about Blog content.</p>
      </div>
      </BaseLayout>
    );
  }
  
  export default Blog;