import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { BookmarkIcon, XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

export const NoteModal = ({ message, onSave, onClose }) => {
  const [note, setNote] = useState({
    title: '',
    content: '',
    tags: '',
  });
  const [error, setError] = useState('');

  const handleSave = async () => {
    try {
      if (!note.title.trim()) {
        setError('Please provide a title for your note');
        return;
      }

      await onSave({
        ...note,
        tags: note.tags.split(',').map(tag => tag.trim()).filter(Boolean),
        original_message: {
          role: message.role,
          content: message.content,
          timestamp: message.timestamp,
          metadata: message.metadata
        }
      });
      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl">
        <div className="p-4 border-b flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-900">Save to Notes</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4">
              <div className="flex">
                <ExclamationCircleIcon className="h-5 w-5 text-red-400" />
                <div className="ml-3">
                  <p className="text-sm text-red-800">{error}</p>
                </div>
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-900">Title</label>
            <input
              type="text"
              value={note.title}
              onChange={(e) => setNote({...note, title: e.target.value})}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Enter note title"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-900">Notes (optional)</label>
            <textarea
              value={note.content}
              onChange={(e) => setNote({...note, content: e.target.value})}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              rows={4}
              placeholder="Add additional notes or leave empty to save just the message"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-900">Tags (comma-separated)</label>
            <input
              type="text"
              value={note.tags}
              onChange={(e) => setNote({...note, tags: e.target.value})}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="tag1, tag2, tag3"
            />
          </div>

          <div className="flex justify-end space-x-3 mt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center space-x-2"
            >
              <BookmarkIcon className="h-4 w-4" />
              <span>Save Note</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChatMessage = ({ message, onSaveNote }) => {
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [markdownError, setMarkdownError] = useState(false);

  const sanitizeContent = (content) => {
    if (typeof content !== 'string') return '';
    return content.trim().replace(/^,\s*/, '').replace(/,\s*$/, '');
  };

  const renderMarkdown = (content) => {
    if (markdownError || !content) {
      return <div className="text-sm whitespace-pre-wrap">{content}</div>;
    }

    try {
      const sanitizedContent = sanitizeContent(content);
      return (
        <div className="prose prose-sm max-w-none">
          <ReactMarkdown>{sanitizedContent}</ReactMarkdown>
        </div>
      );
    } catch (error) {
      console.error('Markdown rendering error:', error);
      setMarkdownError(true);
      return <div className="text-sm whitespace-pre-wrap">{content}</div>;
    }
  };

  return (
    <div className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
      <div className={`max-w-3/4 rounded-lg px-4 py-2 relative group ${
        message.role === 'user' ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800'
      }`}>
        <div className="absolute top-2 right-2 bg-white rounded-full opacity-100 hover:bg-gray-100 z-10">
          <button
            onClick={() => setShowNoteModal(true)}
            className="p-2 rounded-full border border-gray-300"
            title="Save to Notes"
          >
            <BookmarkIcon className="h-4 w-4 text-indigo-600" />
          </button>
        </div>
        
        <div className="mr-8">
          {renderMarkdown(message.content)}
        </div>
        
        <p className="text-xs text-gray-500 mt-1">
          {new Date(message.timestamp).toLocaleString()}
        </p>

        {showNoteModal && (
          <NoteModal
            message={message}
            onSave={onSaveNote}
            onClose={() => setShowNoteModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default ChatMessage;