import React, { useState, useEffect, useCallback, useMemo } from 'react';
import BaseLayout from './BaseLayout';
import ReactMarkdown from 'react-markdown';
import { useTheme } from './ThemeContexxt';
import { 
  ExclamationCircleIcon, 
  PaperAirplaneIcon,
  QuestionMarkCircleIcon,
  GlobeAltIcon,
  ChatBubbleLeftRightIcon,
  DocumentTextIcon,
  TrashIcon,
  CpuChipIcon,
  BookmarkIcon,
  XMarkIcon,
  StarIcon,
  Bars3Icon,
} from '@heroicons/react/24/outline';
import PromptTemplateSearch from '../components/PromptTemplateSearch';
import { 
  fetchPromptTemplate,  
  fetchTemplateNamesByUseCase, 
  fetchAllUseCases, 
  fetchTemplateDetails,
  fetchLLMs,
  notes, 
  searchPromptTemplates,
  toggleTemplateFavorite,
  fetchFavoriteTemplates 
} from '../services/api';
import TemplateCard from '../components/TemplateCard';



const NoteModal = ({ message, onSave, onClose }) => {
  const [note, setNote] = useState({
    title: '',
    content: '',
    tags: '',
  });
  const [error, setError] = useState('');

  const handleSave = async () => {
    try {
      if (!note.title.trim()) {
        setError('Please provide a title for your note');
        return;
      }

      await onSave({
        ...note,
        tags: note.tags.split(',').map(tag => tag.trim()).filter(Boolean),
        original_message: {
          role: message.role,
          content: message.content,
          timestamp: message.timestamp,
          metadata: message.metadata
        }
      });
      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl">
        <div className="p-4 border-b flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-900">Save to Notes</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4">
              <div className="flex">
                <ExclamationCircleIcon className="h-5 w-5 text-red-400" />
                <div className="ml-3">
                  <p className="text-sm text-red-800">{error}</p>
                </div>
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-900">Title</label>
            <input
              type="text"
              value={note.title}
              onChange={(e) => setNote({...note, title: e.target.value})}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Enter note title"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-900">Notes (optional)</label>
            <textarea
              value={note.content}
              onChange={(e) => setNote({...note, content: e.target.value})}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              rows={4}
              placeholder="Add additional notes or leave empty to save just the message"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-900">Tags (comma-separated)</label>
            <input
              type="text"
              value={note.tags}
              onChange={(e) => setNote({...note, tags: e.target.value})}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="tag1, tag2, tag3"
            />
          </div>

          <div className="flex justify-end space-x-3 mt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center space-x-2"
            >
              <BookmarkIcon className="h-4 w-4" />
              <span>Save Note</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const ChatMessage = ({ message, onSaveNote }) => {
  const { darkMode } = useTheme();
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [markdownError, setMarkdownError] = useState(false);

  // Helper to ensure content is properly formatted for markdown
  const sanitizeContent = (content) => {
    if (typeof content !== 'string') {
      return '';
    }
    // Remove any leading/trailing commas and whitespace
    return content.trim().replace(/^,\s*/, '').replace(/,\s*$/, '');
  };

  // Safely render markdown content
  const renderMarkdown = (content) => {
    if (markdownError || !content) {
      return <div className="text-sm whitespace-pre-wrap">{content}</div>;
    }

    try {
      const sanitizedContent = sanitizeContent(content);
      return (
        <div className="prose prose-sm max-w-none">
          <ReactMarkdown>
            {sanitizedContent}
          </ReactMarkdown>
        </div>
      );
    } catch (error) {
      console.error('Markdown rendering error:', error);
      setMarkdownError(true);
      return <div className="text-sm whitespace-pre-wrap">{content}</div>;
    }
  };
  const messageClasses = message.role === 'user'
    ? darkMode 
      ? 'bg-blue-900 text-white' 
      : 'bg-blue-100 text-blue-800'
    : darkMode
      ? 'bg-gray-700 text-white'
      : 'bg-gray-100 text-gray-800';

      return (
        <div className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
          <div className={`max-w-3/4 rounded-lg px-4 py-2 relative group ${messageClasses}`}>
            <div className="absolute top-2 right-2 bg-white rounded-full opacity-100 hover:bg-gray-100 z-10">
              <button
                onClick={() => setShowNoteModal(true)}
                className="p-2 rounded-full border border-gray-300"
                title="Save to Notes"
              >
                <BookmarkIcon className="h-4 w-4 text-indigo-600" />
              </button>
            </div>
            
            <div className="mr-8">
              {renderMarkdown(message.content)}
            </div>
            
            <p className={`text-xs ${darkMode ? 'text-gray-400' : 'text-gray-500'} mt-1`}>
              {new Date(message.timestamp).toLocaleString()}
            </p>
    
            {showNoteModal && (
              <NoteModal
                message={message}
                onSave={onSaveNote}
                onClose={() => setShowNoteModal(false)}
              />
            )}
          </div>
        </div>
      );
    };

const PromptTemplatePage = () => {
  const { darkMode } = useTheme();
  const [showSidebar, setShowSidebar] = useState(false);
  // Existing state variables
  const [variables, setVariables] = useState({});
  const [templateVariables, setTemplateVariables] = useState([]);
  const [templateNames, setTemplateNames] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [useCases, setUseCases] = useState([]);
  const [selectedUseCase, setSelectedUseCase] = useState('');
  const [input, setInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [templateDetails, setTemplateDetails] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [llms, setLLMs] = useState([]);
  const [selectedLLM, setSelectedLLM] = useState('gpt-4');
  const [view, setView] = useState('all');
  
  // New state for improvements
  const [favoriteTemplates, setFavoriteTemplates] = useState([]);
  const [templateStats, setTemplateStats] = useState({});
  const handleVariableChange = (key, value) => {
    setVariables(prev => ({ ...prev, [key]: value }));
  };
  const clearChat = () => {
    setChatHistory([]);
    setError('');
    setSessionId(null);
    localStorage.removeItem('chatSessionId');
  };

  // Theme-aware class generators
  const themeClasses = useMemo(() => ({
    container: `${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`,
    sidebar: `w-1/3 ${darkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} p-4 overflow-y-auto border-r`,
    input: `w-full rounded-md ${
      darkMode 
        ? 'bg-gray-700 border-gray-600 text-white focus:border-blue-500' 
        : 'bg-white border-gray-300 text-gray-900 focus:border-indigo-300'
    } shadow-sm focus:ring focus:ring-opacity-50`,
    label: `block text-sm font-medium ${
      darkMode ? 'text-gray-200' : 'text-gray-900'
    }`,
    button: {
      primary: `w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium ${
        darkMode 
          ? 'bg-blue-600 text-white hover:bg-blue-700' 
          : 'bg-indigo-600 text-white hover:bg-indigo-700'
      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed`,
      secondary: `px-4 py-2 border rounded-md ${
        darkMode 
          ? 'border-gray-600 text-gray-200 hover:bg-gray-700' 
          : 'border-gray-300 text-gray-700 hover:bg-gray-50'
      }`
    },
    chat: {
      container: `flex-1 p-4 overflow-y-auto ${darkMode ? 'bg-gray-800' : 'bg-gray-50'}`,
      message: {
        user: darkMode ? 'bg-blue-900 text-white' : 'bg-blue-100 text-blue-800',
        assistant: darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'
      }
    }
  }), [darkMode]);


  useEffect(() => {
    const storedSessionId = localStorage.getItem('chatSessionId');
    if (storedSessionId) {
      setSessionId(storedSessionId);
    }
  }, []);
  // Add near your other useEffects
  useEffect(() => {
    const loadAllTemplates = async () => {
      try {
        if (view === 'favorites' && templateNames.length === 0) {
          // Fetch all templates when in favorites view
          const allTemplates = await fetchTemplateNamesByUseCase('');
          setTemplateNames(allTemplates);
        }
      } catch (err) {
        console.error('Error loading all templates:', err);
      }
    };
    loadAllTemplates();
  }, [view, templateNames.length]);

  useEffect(() => {
    const fetchUseCases = async () => {
      try {
        const cases = await fetchAllUseCases();
        setUseCases(cases);
      } catch (err) {
        setError('Failed to fetch use cases');
      }
    };
    fetchUseCases();
  }, []);

  useEffect(() => {
    const fetchLLMData = async () => {
      try {
        const llmList = await fetchLLMs();
        setLLMs(llmList);
      } catch (err) {
        setError('Failed to fetch LLMs');
      }
    };
    fetchLLMData();
  }, []);

  useEffect(() => {
    const fetchTemplateNames = async () => {
      if (selectedUseCase) {
        try {
          const names = await fetchTemplateNamesByUseCase(selectedUseCase);
          setTemplateNames(names);
          setSelectedTemplate('');
          setTemplateVariables([]);
          setVariables({});
        } catch (err) {
          setError('Failed to fetch template names');
        }
      } else {
        setTemplateNames([]);
        setSelectedTemplate('');
        setTemplateVariables([]);
        setVariables({});
      }
    };
    fetchTemplateNames();
  }, [selectedUseCase]);
  useEffect(() => {
    const savedStats = localStorage.getItem('templateStats');
    if (savedStats) {
      setTemplateStats(JSON.parse(savedStats));
    }
  }, []);
  
  useEffect(() => {
    if (Object.keys(templateStats).length > 0) {
      localStorage.setItem('templateStats', JSON.stringify(templateStats));
    }
  }, [templateStats]);
  useEffect(() => {
    const fetchTemplateDetailsData = async () => {
      if (selectedTemplate) {
        try {
          const details = await fetchTemplateDetails(selectedTemplate);
          setTemplateDetails(details);
          
          if (details && details.input_variables && details.input_variables.variables) {
            setTemplateVariables(details.input_variables.variables);
            
            const initialVariables = {};
            details.input_variables.variables.forEach(variable => {
              initialVariables[variable.name] = variable.type === 'integer' ? 0 : '';
            });
            setVariables(initialVariables);
          } else {
            console.error('Invalid template details structure:', details);
            setError('Invalid template details received');
          }
        } catch (err) {
          console.error('Error fetching template details:', err);
          setError(`Failed to fetch template details: ${err.message}`);
        }
      }
    };
    fetchTemplateDetailsData();
  }, [selectedTemplate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    const startTime = Date.now(); // Add this line at the start
  
    try {
      const currentSessionId = sessionId || localStorage.getItem('chatSessionId');
  
      const apiPayload = {
        payload: {
          data: {
            template_name: selectedTemplate,
            use_case: selectedUseCase,
            input: input,
            variables: {
              ...variables,
              input: input,
              chat_history: JSON.stringify(chatHistory),
              session_id: currentSessionId,
              model_name: selectedLLM
            }
          }
        }
      };

      
  
      console.log('Sending payload:', JSON.stringify(apiPayload, null, 2));
  
      const result = await fetchPromptTemplate(apiPayload);
  
      console.log('Received result:', result);
  
      if (result && result.status === 'success') {
        // Update template stats
        setTemplateStats(prev => ({
          ...prev,
          [selectedTemplate]: {
            uses: (prev[selectedTemplate]?.uses || 0) + 1,
            avgTokens: result.token_usage?.total_tokens || 0,
            successRate: prev[selectedTemplate]?.successRate || 100,
            avgResponseTime: Date.now() - startTime
          }
        }));
  
        if (result.content) {
          if (result.chat_history) {
            const parsedChatHistory = JSON.parse(result.chat_history);
            setChatHistory(parsedChatHistory);
          } else {
            setChatHistory(prevHistory => [
              ...prevHistory,
              { role: 'user', content: input, timestamp: new Date().toISOString() },
              { role: 'assistant', content: result.content, timestamp: new Date().toISOString() }
            ]);
          }
          
          setInput('');
  
          if (result.session_id) {
            setSessionId(result.session_id);
            localStorage.setItem('chatSessionId', result.session_id);
          }
        }
      } else {
        throw new Error(result.message || 'Unexpected response structure');
      }
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(err.message || 'An error occurred while fetching the response');
    } finally {
      setLoading(false);
    }
  };
  const handleSaveNote = async (noteData) => {
    console.log('Attempting to save note:', noteData); 
    try {
      const response = await notes.create(noteData);
      console.log('Note saved:', response);
    } catch (error) {
      console.error('Error saving note:', error);
      setError('Failed to save note: ' + error.message);
    }
  };

  const [favoriteLoading, setFavoriteLoading] = useState({});

  const toggleFavorite = useCallback(async (templateName) => {
    if (favoriteLoading[templateName]) return;
    
    try {
      setFavoriteLoading(prev => ({ ...prev, [templateName]: true }));
      
      // Optimistic update
      setFavoriteTemplates(prev => {
        if (prev.includes(templateName)) {
          return prev.filter(t => t !== templateName);
        }
        return [...prev, templateName];
      });

      await toggleTemplateFavorite(templateName);
      
      // Refresh favorites from backend
      const response = await fetchFavoriteTemplates();
      setFavoriteTemplates(response.templates || []);
    } catch (err) {
      // Revert on error
      const response = await fetchFavoriteTemplates();
      setFavoriteTemplates(response.templates || []);
      setError('Failed to update favorite status');
    } finally {
      setFavoriteLoading(prev => ({ ...prev, [templateName]: false }));
    }
  }, []);

  // Add these debug effects
useEffect(() => {
  console.log('FavoriteTemplates state updated:', favoriteTemplates);
}, [favoriteTemplates]);

useEffect(() => {
  console.log('TemplateNames state updated:', templateNames);
}, [templateNames]);

useEffect(() => {
  const loadFavorites = async () => {
    try {
      console.log('Loading favorites...');
      const favoritesArray = await fetchFavoriteTemplates();
      console.log('Received favorites array:', favoritesArray);
      
      // Directly set the favorites array
      setFavoriteTemplates(favoritesArray);
      console.log('Updated favorites state:', favoritesArray);

      // If we're in favorites view and have templates
      if (view === 'favorites') {
        const allTemplates = await fetchTemplateNamesByUseCase('');
        console.log('All templates:', allTemplates);
        
        // Filter to show only favorites
        const filteredTemplates = allTemplates.filter(template => 
          favoritesArray.includes(template)
        );
        console.log('Filtered templates:', filteredTemplates);
        
        setTemplateNames(filteredTemplates);
      }
    } catch (err) {
      console.error('Error loading favorites:', err);
    }
  };
  loadFavorites();

  // Also fetch all templates when switching to favorites view
  if (view === 'favorites') {
    const fetchAllTemplates = async () => {
      try {
        const allTemplates = await fetchTemplateNamesByUseCase('');
        setTemplateNames(allTemplates);
      } catch (err) {
        console.error('Error fetching all templates:', err);
      }
    };
    fetchAllTemplates();
  }
}, [view, setTemplateNames]);

const handleCopyChat = () => {
  const chatContent = chatHistory.map(message => 
    `${message.role}: ${message.content}`
  ).join('\n\n');

  navigator.clipboard.writeText(chatContent).then(() => {
    alert('Chat copied to clipboard!');
  }).catch(err => {
    console.error('Failed to copy chat: ', err);
    alert('Failed to copy chat. Please try again.');
  });
};  
return (
  <BaseLayout>
    <div className="flex flex-col h-screen lg:flex-row">
      {/* Mobile Header */}
      <div className="lg:hidden flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 fixed top-0 left-0 right-0 bg-white dark:bg-gray-800 z-50">
        <h1 className="text-xl font-bold">Prompt Template</h1>
        <button
          onClick={() => setShowSidebar(!showSidebar)}
          className="p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800"
          aria-label="Toggle sidebar"
        >
          {showSidebar ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
      </div>

      {/* Mobile Header Spacer */}
      <div className="h-16 lg:hidden" />

      {/* Sidebar */}
      <div 
        className={`
          fixed inset-y-0 left-0 z-40 
          w-full md:w-[480px] lg:w-[520px] xl:w-[600px]
          transform transition-transform duration-300 ease-in-out
          ${showSidebar ? 'translate-x-0' : '-translate-x-full'}
          lg:relative lg:transform-none lg:translate-x-0
          ${themeClasses.sidebar}
          overflow-y-auto h-[calc(100vh-4rem)] lg:h-screen
          ${darkMode ? 'bg-gray-800' : 'bg-white'}
          border-r border-gray-200 dark:border-gray-700
          mt-16 lg:mt-0
        `}
      >
        <div className="p-6 lg:p-8">
          {/* Desktop Header */}
          <h1 className={`hidden lg:flex text-2xl font-bold mb-6 items-center ${darkMode ? 'text-white' : 'text-gray-900'}`}>
            <QuestionMarkCircleIcon className="h-6 w-6 mr-2" />
            Prompt Template
          </h1>

          <form onSubmit={handleSubmit} className="space-y-8">
            {/* Search and Templates Section */}
            <div className="space-y-6">
              <PromptTemplateSearch
                onSearch={searchPromptTemplates}
                selectedUseCase={selectedUseCase}
                setSelectedTemplate={setSelectedTemplate}
                setTemplateNames={setTemplateNames}
                className={`${themeClasses.input} text-base`}
              />
              {/* Template Navigation */}
              <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700 pb-4">
                <h3 className={`font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                  Templates
                </h3>
                <div className="flex items-center space-x-2">
                  <button
                    className={`px-4 py-2 rounded-md ${
                      view === 'favorites' 
                        ? 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900 dark:text-indigo-200' 
                        : darkMode 
                          ? 'bg-gray-700 text-white hover:bg-gray-600' 
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                    onClick={() => setView('favorites')}
                  >
                    Favorites
                  </button>
                  <button
                    className={`px-4 py-2 rounded-md ${
                      view === 'all'
                        ? 'bg-indigo-100 text-indigo-700 dark:bg-indigo-900 dark:text-indigo-200'
                        : darkMode 
                          ? 'bg-gray-700 text-white hover:bg-gray-600' 
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                    onClick={() => setView('all')}
                  >
                    All
                  </button>
                </div>
              </div>

              {/* Template Cards */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                {templateNames
                  .filter(template => view === 'all' || favoriteTemplates.includes(template))
                  .map(template => (
                    <TemplateCard
                      key={template}
                      template={template}
                      stats={templateStats[template]}
                      isFavorite={favoriteTemplates.includes(template)}
                      onSelect={setSelectedTemplate}
                      onToggleFavorite={toggleFavorite}
                      isLoading={favoriteLoading[template]}
                      description={templateDetails?.name === template ? templateDetails.description : ''}
                    />
                  ))}
              </div>
            </div>

            {/* Form Controls Grid */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {/* Left Column - Selects */}
              <div className="space-y-6">
                {/* Use Case Select */}
                <div className="space-y-2">
                  <label htmlFor="useCaseSelect" className={`${themeClasses.label} flex items-center`}>
                    <ChatBubbleLeftRightIcon className="h-5 w-5 mr-1" />
                    Use Case (Optional)
                  </label>
                  <select
                    id="useCaseSelect"
                    value={selectedUseCase}
                    onChange={(e) => setSelectedUseCase(e.target.value)}
                    className={`${themeClasses.input} h-11 w-full`}
                  >
                    <option value="">Select a use case</option>
                    {useCases.map((useCase) => (
                      <option key={useCase} value={useCase}>{useCase}</option>
                    ))}
                  </select>
                </div>
                {/* Template Select */}
                <div className="space-y-2">
                  <label htmlFor="templateSelect" className={`${themeClasses.label} flex items-center`}>
                    <DocumentTextIcon className="h-5 w-5 mr-1" />
                    Select Template
                  </label>
                  <select
                    id="templateSelect"
                    value={selectedTemplate}
                    onChange={(e) => setSelectedTemplate(e.target.value)}
                    className={`${themeClasses.input} h-11 w-full`}
                    required={view === 'all'}
                  >
                    <option value="">Select a template</option>
                    {templateNames
                      .filter(name => view === 'all' || favoriteTemplates.includes(name))
                      .map((name) => (
                        <option key={name} value={name}>{name}</option>
                      ))}
                  </select>
                </div>

                {/* LLM Select */}
                <div className="space-y-2">
                  <label htmlFor="llmSelect" className={`${themeClasses.label} flex items-center`}>
                    <CpuChipIcon className="h-5 w-5 mr-1" />
                    Select Model
                  </label>
                  <select
                    id="llmSelect"
                    value={selectedLLM}
                    onChange={(e) => setSelectedLLM(e.target.value)}
                    className={`${themeClasses.input} h-11 w-full`}
                    required
                  >
                    <option value="">Select a model</option>
                    {llms.map((llm) => (
                      <option key={llm.name} value={llm.name}>{llm.display_name}</option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Right Column - Variables */}
              <div className="space-y-2">
                <label className={`${themeClasses.label} flex items-center`}>
                  <GlobeAltIcon className="h-5 w-5 mr-1" />
                  Variables
                </label>
                <div className="space-y-4">
                  {templateVariables.map((variable) => (
                    <div key={variable.name} className="flex flex-col">
                      <label htmlFor={variable.name} className={`${themeClasses.label}`}>
                        {variable.name} {variable.required && <span className="text-red-500">*</span>}
                      </label>
                      <input
                        id={variable.name}
                        type={variable.type === 'integer' ? 'number' : 'text'}
                        value={variables[variable.name] || ''}
                        onChange={(e) => handleVariableChange(variable.name, e.target.value)}
                        className={`${themeClasses.input} h-11 w-full`}
                        placeholder={variable.description}
                        required={variable.required}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Input and Submit - Full Width */}
            <div className="space-y-6">
              <div className="space-y-2">
                <label htmlFor="input" className={`${themeClasses.label} flex items-center`}>
                  <ChatBubbleLeftRightIcon className="h-5 w-5 mr-1" />
                  Input
                </label>
                <textarea
                  id="input"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  className={`${themeClasses.input} min-h-[150px]`}
                  placeholder="Enter your prompt input here"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={loading || (view === 'all' && !selectedTemplate)}
                  className={`${themeClasses.button.primary} h-12 px-8`}
                >
                  {loading ? 'Processing...' : 'Send'}
                  {!loading && <PaperAirplaneIcon className="ml-2 h-5 w-5" />}
                </button>
              </div>
            </div>
          </form>

          {/* Error Display */}
          {error && (
            <div className={`mt-4 ${darkMode ? 'bg-red-900 border-red-700' : 'bg-red-50 border-red-400'} border-l-4 p-4 rounded-md`}>
              <div className="flex">
                <ExclamationCircleIcon className={`h-5 w-5 ${darkMode ? 'text-red-500' : 'text-red-400'}`} />
                <div className="ml-3">
                  <h3 className={`text-sm font-medium ${darkMode ? 'text-red-200' : 'text-red-800'}`}>Error</h3>
                  <div className={`mt-2 text-sm ${darkMode ? 'text-red-200' : 'text-red-700'}`}>
                    <p>{error}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Overlay */}
      {showSidebar && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={() => setShowSidebar(false)}
        />
      )}

      {/* Chat Area */}
      <div className={`
        flex-1 overflow-hidden
        ${showSidebar ? 'hidden lg:block' : 'block'}
        ${darkMode ? 'bg-gray-900' : 'bg-gray-50'}
        mt-16 lg:mt-0
      `}>
        <div className="h-full overflow-y-auto px-4 py-6 lg:px-8">
          <div className="max-w-5xl mx-auto">
            <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
              <div className="p-6">
                <div className="flex justify-between items-center mb-4 sticky top-0 bg-inherit z-10 pb-4 border-b">
                  <h2 className={`text-lg font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                    Chat History
                  </h2>
                  <div className="flex space-x-2">
                    <button
                      onClick={handleCopyChat}
                      className="inline-flex items-center px-3 py-1 text-sm rounded-md bg-indigo-100 text-indigo-700 hover:bg-indigo-200 dark:bg-indigo-900 dark:text-indigo-200"
                    >
                      Copy Chat
                    </button>
                    <button
                      onClick={clearChat}
                      className="inline-flex items-center px-3 py-1 text-sm rounded-md bg-red-100 text-red-700 hover:bg-red-200 dark:bg-red-900 dark:text-red-200"
                    >
                      Clear Chat
                    </button>
                  </div>
                </div>
                
                <div className="space-y-4">
                  {chatHistory.map((message, index) => (
                    <ChatMessage
                      key={index}
                      message={message}
                      onSaveNote={handleSaveNote}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
);
};
export default PromptTemplatePage;
