import React, { useState } from 'react';
import BaseLayout from './BaseLayout';
import { 
  ChatBubbleLeftRightIcon,
  NewspaperIcon,
  BuildingLibraryIcon,
  DocumentIcon,
  UserGroupIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline';
import TemplateLibrary from '../components/TemplateLibrary';

const AboutPage = () => {
  const [openSection, setOpenSection] = useState('overview');
  const [darkMode, setDarkMode] = useState(true);

  const sections = [
    { id: 'overview', title: 'Platform Overview' },
    { id: 'features', title: 'Feature Guide' },
    { id: 'getting-started', title: 'Getting Started' },
    { id: 'templates', title: 'Template Library' },
    { id: 'faq', title: 'FAQ' }
  ];

  const features = [
    {
      id: 'ai-assistants',
      title: 'AI Assistants',
      description: 'Our suite of AI-powered tools designed to enhance your productivity',
      items: [
        {
          icon: ChatBubbleLeftRightIcon,
          name: 'Chat Bot',
          description: 'An intelligent assistant that uses customizable prompt templates to help with various tasks.',
          keyFeatures: [
            'Multiple AI models including GPT-4 and Claude',
            'Customizable prompt templates for different use cases',
            'Conversation history tracking',
            'Token usage monitoring'
          ],
          guide: 'To use the Chat Bot: Select a use case, choose a template, pick your preferred AI model, and start your conversation. Your chat history is saved for future reference.'
        },
        {
          icon: BuildingLibraryIcon,
          name: 'EnterpriseChatBot',
          description: 'Advanced chat system with RAG (Retrieval Augmented Generation) capabilities.',
          keyFeatures: [
            'Integration with company knowledge base',
            'Context-aware responses',
            'Document-based question answering',
            'Secure data handling'
          ],
          guide: 'To use EnterpriseChatBot: Choose your document store, ask questions, and receive answers based on your organization\'s knowledge base.'
        },
        {
          icon: DocumentIcon,
          name: 'DocumentQA',
          description: 'Interactive system for querying and analyzing documents.',
          keyFeatures: [
            'PDF document support',
            'Intelligent text extraction',
            'Context-aware answers',
            'Source attribution'
          ],
          guide: 'To use DocumentQA: Upload your PDF document, wait for processing, then ask questions about its content. The system will provide answers with relevant source citations.'
        }
      ]
    },
    {
      id: 'content-analysis',
      title: 'Content & Analysis Tools',
      description: 'Tools for content discovery, analysis, and management',
      items: [
        {
          icon: NewspaperIcon,
          name: 'Reddit Posts',
          description: 'Browse and analyze Reddit content with advanced filtering.',
          keyFeatures: [
            'Custom subreddit tracking',
            'Content filtering and search',
            'Comment analysis',
            'Trend detection'
          ],
          guide: 'To use Reddit Posts: Enter your search terms, select time range and sorting options, and explore the results. You can dive deeper into comments and save interesting posts.'
        },
        {
          icon: UserGroupIcon,
          name: 'Social Analytics',
          description: 'Comprehensive social media analysis tools.',
          keyFeatures: [
            'Engagement metrics',
            'Trend analysis',
            'Sentiment tracking',
            'Report generation'
          ],
          guide: 'To use Social Analytics: Connect your social accounts, select metrics to track, and generate insights about your social media presence.'
        }
      ]
    }
  ];

  // Rest of the component remains the same...
  return (
    <BaseLayout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 dark:text-text-primary text-gray-900">
            About Synthworks
          </h1>
          <p className="text-xl dark:text-text-primary/90 text-gray-600 max-w-3xl mx-auto">
            Your comprehensive platform for AI-powered content analysis, document processing, and intelligent assistance.
          </p>
        </div>

        {/* Navigation Tabs */}
        <div className="border-b border-gray-200 dark:border-gray-200/20 mb-8">
          <nav className="flex space-x-8">
            {sections.map((section) => (
              <button
                key={section.id}
                onClick={() => setOpenSection(section.id)}
                className={`py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200 ${
                  openSection === section.id
                    ? 'border-accent text-accent'
                    : 'border-transparent dark:text-text-primary/80 text-gray-600 hover:text-gray-900 dark:hover:text-accent/90 hover:border-accent/50'
                }`}
              >
                {section.title}
              </button>
            ))}
          </nav>
        </div>

        {/* Content Sections */}
        <div className="space-y-12">
          {openSection === 'overview' && (
            <div className="prose dark:prose-invert max-w-none">
              <h2 className="text-2xl font-bold mb-4 dark:text-text-primary text-gray-900">
                Welcome to Synthworks
              </h2>
              <p className="mb-4 dark:text-text-primary/90 text-gray-600">
                Synthworks is a comprehensive AI platform that combines powerful language models,
                document analysis, and content management tools to help you work smarter and more efficiently.
              </p>
              <h3 className="text-xl font-semibold mb-3 dark:text-text-primary text-gray-900">
                Our Core Capabilities
              </h3>
              <ul className="space-y-2 dark:text-text-primary/90 text-gray-600">
                <li>Advanced AI chat interfaces with multiple language models</li>
                <li>Document analysis and question-answering systems</li>
                <li>Social media content analysis and tracking</li>
                <li>Enterprise knowledge base integration</li>
                <li>Usage tracking and analytics</li>
              </ul>
            </div>
          )}

          {openSection === 'features' && (
            <div className="space-y-8">
              {features.map((category) => (
                <div key={category.id} className="bg-white dark:bg-background-light/50 rounded-lg p-6 shadow-lg">
                  <h2 className="text-2xl font-bold mb-4 dark:text-text-primary text-gray-900">
                    {category.title}
                  </h2>
                  <p className="mb-6 dark:text-text-primary/80 text-gray-600">
                    {category.description}
                  </p>
                  
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {category.items.map((feature) => (
                      <div key={feature.name} className="bg-gray-50 dark:bg-background rounded-lg p-6 border border-gray-200 dark:border-primary/20">
                        <div className="flex items-center mb-4">
                          <feature.icon className="w-6 h-6 text-accent mr-2" />
                          <h3 className="text-lg font-semibold dark:text-text-primary text-gray-900">
                            {feature.name}
                          </h3>
                        </div>
                        <p className="mb-4 dark:text-text-primary/80 text-gray-600">
                          {feature.description}
                        </p>
                        
                        <div className="mb-4">
                          <h4 className="font-semibold mb-2 dark:text-text-primary text-gray-900">
                            Key Features:
                          </h4>
                          <ul className="list-disc list-inside text-sm dark:text-text-primary/80 text-gray-600">
                            {feature.keyFeatures.map((item, index) => (
                              <li key={index} className="mb-1">{item}</li>
                            ))}
                          </ul>
                        </div>
                        
                        <div className="text-sm">
                          <h4 className="font-semibold mb-2 dark:text-text-primary text-gray-900">
                            Quick Guide:
                          </h4>
                          <p className="dark:text-text-primary/80 text-gray-600">
                            {feature.guide}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {openSection === 'getting-started' && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold mb-4 dark:text-text-primary text-gray-900">
                Getting Started with Synthworks
              </h2>
              
              <div className="bg-white dark:bg-background-light/50 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-4 dark:text-text-primary text-gray-900">
                  1. Account Setup
                </h3>
                <ul className="list-disc list-inside space-y-2 dark:text-text-primary/80 text-gray-600">
                  <li>Create an account using the Register page</li>
                  <li>Complete your profile information</li>
                  <li>Set up billing preferences if required</li>
                </ul>
              </div>

              <div className="bg-white dark:bg-background-light/50 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-4 dark:text-text-primary text-gray-900">
                  2. Choose Your Tools
                </h3>
                <ul className="list-disc list-inside space-y-2 dark:text-text-primary/80 text-gray-600">
                  <li>Explore available features from the dashboard</li>
                  <li>Start with basic Chat Bot functionality</li>
                  <li>Graduate to advanced features like DocumentQA as needed</li>
                </ul>
              </div>

              <div className="bg-white dark:bg-background-light/50 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-4 dark:text-text-primary text-gray-900">
                  3. Best Practices
                </h3>
                <ul className="list-disc list-inside space-y-2 dark:text-text-primary/80 text-gray-600">
                  <li>Keep your queries clear and specific</li>
                  <li>Save important conversations and results</li>
                  <li>Monitor your token usage in the billing section</li>
                  <li>Use appropriate templates for different use cases</li>
                </ul>
              </div>
            </div>
          )}
          {openSection === 'templates' && (
            <div className="space-y-6">
              <TemplateLibrary />
            </div>
          )}

          {openSection === 'faq' && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold mb-4 dark:text-text-primary text-gray-900">
                Frequently Asked Questions
              </h2>
              
              {[
                {
                  question: "What AI models are available?",
                  answer: "We support multiple AI models including GPT-4, Claude, and Gemini. Each model has different strengths and pricing."
                },
                {
                  question: "How is billing calculated?",
                  answer: "Billing is based on token usage across different features. You can monitor your usage in the billing section."
                },
                {
                  question: "What file formats are supported in DocumentQA?",
                  answer: "Currently, we support PDF files. We plan to add support for more formats in future updates."
                },
                {
                  question: "How is my data handled?",
                  answer: "All data is processed securely and not stored unless explicitly saved. Enterprise features include additional security measures."
                }
              ].map((item, index) => (
                <div key={index} className="bg-white dark:bg-background-light/50 rounded-lg p-6">
                  <h3 className="text-xl font-semibold mb-2 dark:text-text-primary text-gray-900">
                    {item.question}
                  </h3>
                  <p className="dark:text-text-primary/80 text-gray-600">
                    {item.answer}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </BaseLayout>
  );
};
export default AboutPage;