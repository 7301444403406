import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const requestPasswordReset = async (email) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/password-reset/request`, { email });
      return { success: true, message: response.data.message };
    } catch (error) {
      console.error('Password reset request failed:', error);
      return { 
        success: false, 
        message: error.response?.data?.message || 'Failed to request password reset'
      };
    }
  };

  const resetPassword = {
    verifyToken: async (token) => {
      try {
        const response = await axios.post(`${API_BASE_URL}/password-reset/verify-token`, { token });
        return response.data.valid;
      } catch (error) {
        return false;
      }
    },
    
    reset: async (token, newPassword) => {
      try {
        const response = await axios.post(`${API_BASE_URL}/password-reset/confirm`, {
          token,
          new_password: newPassword
        });
        return { success: true, message: response.data.message };
      } catch (error) {
        return {
          success: false,
          message: error.response?.data?.message || 'Failed to reset password'
        };
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
        setAuthToken(token);
        fetchUser();
      } else {
        setLoading(false);
      }
    }, []);

    const setAuthToken = (token) => {
        if (token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
          delete axios.defaults.headers.common['Authorization'];
        }
      };

    const fetchUser = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/me`);
        setUser(response.data);
    } catch (error) {
        console.error('Failed to fetch user:', error);
        // If fetching user fails, clear the token
        localStorage.removeItem('token');
        setAuthToken(null);
    } finally {
        setLoading(false);
    }
    };

    const login = async (username, password) => {
        try {
          const response = await axios.post(`${API_BASE_URL}/token`, { username, password });
          const { token } = response.data;
          localStorage.setItem('token', token);
          setAuthToken(token);
          await fetchUser();
          return true;
        } catch (error) {
          console.error('Login failed:', error);
          return false;
        }
      };

    const logout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setUser(null);
    };

    const register = async (username, password, email) => {
        try {
          const response = await axios.post(`${API_BASE_URL}/register`, 
            { username, password, email },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          if (response.data.message) {
            return { success: true, message: response.data.message };
          } else {
            return { success: false, message: response.data.error || 'Registration failed' };
          }
        } catch (error) {
          console.error('Registration failed:', error);
          return { success: false, message: error.response?.data?.error || 'Registration failed' };
        }
      };

  return (
    <AuthContext.Provider value={{ user, login, logout, register, loading, requestPasswordReset }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);