import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { 
  DocumentTextIcon, 
  ExclamationCircleIcon,
  ArrowUpTrayIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  CpuChipIcon,
  TrashIcon 
} from '@heroicons/react/24/outline';
import BaseLayout from './BaseLayout';
import { useInterval } from '../hooks/useInterval';
import { documentApi } from '../services/documentAPI';
import { fetchLLMs, notes } from '../services/api';
import { ChatMessage } from '../components/ChatMessageFeatures';
import PropTypes from 'prop-types';

const DocumentQA = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState('');
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [processingDocs, setProcessingDocs] = useState(new Set());
  const [llms, setLLMs] = useState([]);
  const [selectedLLM, setSelectedLLM] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [sessionId, setSessionId] = useState(null);

  // Load session ID on mount - follows PromptTemplate approach
  useEffect(() => {
    const storedSessionId = localStorage.getItem('docQASessionId');
    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      const newSessionId = `doc_qa_${Date.now()}`;
      setSessionId(newSessionId);
      localStorage.setItem('docQASessionId', newSessionId);
    }
  }, []);

  // Add LLM fetch to initial data loading
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const llmList = await fetchLLMs();
        setLLMs(llmList);
        
        // Set default LLM if available
        if (llmList.length > 0) {
          setSelectedLLM(llmList[0].name);
        }
      } catch (err) {
        console.error('Error fetching LLMs:', err);
        setError('Failed to load LLM data. Please refresh the page.');
      }
    };

    fetchInitialData();
  }, []);

  const handleSaveNote = async (noteData) => {
    try {
      // Use the existing notes service with its built-in auth handling
      const response = await notes.create({
        title: noteData.title,
        content: noteData.content,
        tags: noteData.tags,
        original_message: {
          role: noteData.original_message.role,
          content: noteData.original_message.content,
          timestamp: noteData.original_message.timestamp,
          metadata: noteData.original_message.metadata || {}
        }
      });
      
      console.log('Note saved:', response);
    } catch (error) {
      console.error('Error saving note:', error);
      setError('Failed to save note: ' + error.message);
    }
  };
  // Fetch documents on mount
  useEffect(() => {
    fetchDocuments();
  }, []);

  // Poll for processing documents
  useInterval(() => {
    if (processingDocs.size > 0) {
      checkProcessingStatus();
    }
  }, processingDocs.size > 0 ? 5000 : null);

  const fetchDocuments = async () => {
    try {
      console.log('Fetching documents...');
      const result = await documentApi.getDocuments();
      console.log('Raw API response:', result);
      
      if (result && Array.isArray(result)) {
        console.log('Setting documents:', result);
        setDocuments(result);
        
        // Check for processing documents
        const processing = result
          .filter(doc => doc.status === 'processing' || doc.status === 'pending')
          .map(doc => doc.id);
        setProcessingDocs(new Set(processing));
      } else {
        console.error('Invalid response format:', result);
      }
    } catch (err) {
      console.error('Error fetching documents:', err);
      setError('Failed to fetch documents: ' + err.message);
    }
  };

  const checkProcessingStatus = async () => {
    const processingArray = Array.from(processingDocs);
    const statusChecks = await Promise.all(
      processingArray.map(id => documentApi.getDocumentStatus(id))
    );

    const stillProcessing = new Set();
    statusChecks.forEach((status, index) => {
      if (status.status === 'processing' || status.status === 'pending') {
        stillProcessing.add(processingArray[index]);
      }
    });

    if (stillProcessing.size !== processingDocs.size) {
      fetchDocuments(); // Refresh document list
    }
    setProcessingDocs(stillProcessing);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    if (!file.type.includes('pdf')) {
      setError('Please upload a PDF file');
      return;
    }
    
    setUploading(true);
    setError('');
    
    try {
      const result = await documentApi.uploadDocument(file);
      setDocuments(prev => [...prev, result.document_store]);
      setProcessingDocs(prev => new Set(prev).add(result.document_store.id));
    } catch (err) {
      setError('Failed to upload document: ' + err.message);
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      if (!selectedDoc) {
        throw new Error('Please select a document');
      }
  
      const result = await documentApi.askQuestion(
        selectedDoc,
        question,
        sessionId || undefined,  // Match the existing pattern
        selectedLLM || undefined
      );
  
      if (result.status === 'success') {
        // Use the same chat history update pattern
        setChatHistory(prev => [
          ...prev,
          {
            role: 'user',
            content: question,
            timestamp: new Date().toISOString()
          },
          {
            role: 'assistant',
            content: result.content,
            timestamp: new Date().toISOString(),
            metadata: result.metadata
          }
        ]);
  
        setQuestion('');  // Clear input after success
  
        // Handle session ID the same way
        if (!sessionId) {
          const newSessionId = `doc_qa_${Date.now()}`;
          setSessionId(newSessionId);
          localStorage.setItem('docQASessionId', newSessionId);
        }
      } else {
        throw new Error(result.message || 'Failed to get response');
      }
    } catch (err) {
      console.error('Error in document Q&A:', err);
      setError(err.message || 'An error occurred while processing your request');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('Documents state updated:', documents);
  }, [documents]);

  // Existing helper functions...
  const handleCopyChat = () => {
    const chatContent = chatHistory.map(message => 
      `${message.role.toUpperCase()}: ${message.content}${
        message.metadata?.sources 
          ? '\n\nSources:\n' + message.metadata.sources
            .map(source => `Page ${source.page}: ${source.text}`)
            .join('\n')
          : ''
      }`
    ).join('\n\n---\n\n');

    navigator.clipboard.writeText(chatContent)
      .then(() => {
        alert('Chat copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy chat:', err);
        setError('Failed to copy chat. Please try again.');
      });
  };
  
  const clearChat = () => {
    if (window.confirm('Are you sure you want to clear the chat history?')) {
      setChatHistory([]);
      setQuestion('');
      setError('');
      const newSessionId = `doc_qa_${Date.now()}`;
      setSessionId(newSessionId);
      localStorage.setItem('docQASessionId', newSessionId);
    }
  };

  return (
    <BaseLayout>
      <div className="max-w-4xl mx-auto p-6">
        <div className="mb-8">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold text-gray-900">Document Q&A</h2>
            <div className="relative">
              <input
                type="file"
                onChange={handleFileUpload}
                className="hidden"
                id="file-upload"
                accept=".pdf"
              />
              <label
                htmlFor="file-upload"
                className={`flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                  ${uploading 
                    ? 'bg-indigo-400 cursor-not-allowed' 
                    : 'bg-indigo-600 hover:bg-indigo-700 cursor-pointer'}
                  transition-colors duration-200`}
              >
                {uploading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Uploading...
                  </div>
                ) : (
                  <>
                    <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
                    Upload PDF
                  </>
                )}
              </label>
            </div>
          </div>

          {/* Error Display */}
          {error && (
            <div className="mb-4 bg-red-50 border-l-4 border-red-400 p-4 relative">
              <div className="flex">
                <ExclamationCircleIcon className="h-5 w-5 text-red-400" />
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
                <button 
                  onClick={() => setError('')}
                  className="absolute top-2 right-2 text-red-400 hover:text-red-500"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          )}

          {/* Processing Documents Status */}
          {processingDocs.size > 0 && (
            <div className="mt-4 bg-gray-50 rounded-lg p-4">
              <h3 className="text-sm font-medium text-gray-700 mb-3">Processing Documents</h3>
              {documents
                .filter(doc => processingDocs.has(doc.id))
                .map(doc => (
                  <div key={doc.id} className="mb-4 last:mb-0">
                    <div className="flex items-center mb-2">
                      <DocumentTextIcon className="w-5 h-5 text-gray-400 mr-2" />
                      <span className="text-sm text-gray-600">{doc.name}</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div 
                        className="bg-indigo-600 h-2.5 rounded-full transition-all duration-500"
                        style={{ width: `${doc.get_processing_progress || 0}%` }}
                      ></div>
                    </div>
                  </div>
                ))}
            </div>
          )}

          {/* Main Form */}
          <form onSubmit={handleSubmit} className="space-y-4 mt-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
                <CpuChipIcon className="h-5 w-5 mr-2" />
                Select Model
              </label>
              <select
                value={selectedLLM}
                onChange={(e) => setSelectedLLM(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                required
              >
                <option value="">Choose a model...</option>
                {llms.map((llm) => (
                  <option key={llm.name} value={llm.name}>
                    {llm.display_name}
                  </option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Document
              </label>
              <select
                value={selectedDoc}
                onChange={(e) => setSelectedDoc(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                required
                disabled={documents.length === 0}
              >
                <option value="">Choose a document ({documents.length} available)...</option>
                {documents.map(doc => (
                  <option key={doc.id} value={doc.id}>
                    {doc.name} ({new Date(doc.created_at).toLocaleDateString()})
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Your Question
              </label>
              <textarea
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                rows="3"
                placeholder="Ask a question about the document..."
                required
                disabled={!selectedDoc}
              />
            </div>

            <button
              type="submit"
              disabled={loading || !selectedDoc || !question}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
            >
              {loading ? (
                <div className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Getting Answer...
                </div>
              ) : (
                <>
                  <MagnifyingGlassIcon className="w-5 h-5 mr-2" />
                  Ask Question
                </>
              )}
            </button>
          </form>
        </div>

        {/* Chat History Section */}
        {chatHistory.length > 0 && (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium text-gray-900">Chat History</h2>
                <div className="space-x-2">
                  <button
                    onClick={handleCopyChat}
                    className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                    Copy Chat
                  </button>
                  <button
                    onClick={clearChat}
                    className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <TrashIcon className="h-4 w-4 mr-2" />
                    Clear Chat
                  </button>
                </div>
              </div>
              <div className="space-y-4 max-h-[calc(100vh-12rem)] overflow-y-auto">
                {chatHistory.map((message, index) => (
                  <ChatMessage
                    key={index}
                    message={message}
                    onSaveNote={handleSaveNote}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

export default DocumentQA;