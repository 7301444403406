// src/services/documentApi.js
import { api } from './api';
export const documentApi = {
  uploadDocument: async (file, name) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', name || file.name);

      const response = await api.post('/rag/document-stores/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 100000, // 30 second timeout for large files
      });

      return response.data;
    } catch (error) {
      console.error('Error uploading document:', error);
      throw new Error(error.response?.data?.message || 'Failed to upload document');
    }
  },

  getDocumentStatus: async (documentId) => {
    try {
        const response = await api.get(`/rag/document-stores/status/${documentId}`);;
      return response.data;
    } catch (error) {
      console.error('Error fetching document status:', error);
      throw new Error(error.response?.data?.message || 'Failed to get document status');
    }
  },

  getDocuments: async () => {
    try {
      const response = await api.get('/rag/document-stores/');
      return response.data.document_stores;
    } catch (error) {
      console.error('Error fetching documents:', error);
      throw new Error(error.response?.data?.message || 'Failed to fetch documents');
    }
  },

  askQuestion: async (documentId, question, sessionId = undefined, llmName = undefined) => {
    try {
      const response = await api.post('/rag/response/', {
        document_store_id: documentId,
        question,
        session_id: sessionId,
        llm_name: llmName
      });

      // Match the expected response format
      return {
        status: 'success',
        content: response.data.content,
        metadata: response.data.metadata || {},
        message: response.data.message,
        session_id: response.data.session_id
      };
    } catch (error) {
      console.error('Error in askQuestion:', error);
      if (error.response?.data) {
        throw new Error(error.response.data.message || 'Failed to get response');
      }
      throw error;
    }
  }
};