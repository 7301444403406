import React, { useState, useEffect } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash/debounce';

const PromptTemplateSearch = ({ 
  onSearch, 
  selectedUseCase, 
  setSelectedTemplate, 
  setTemplateNames 
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  // Debounce the search to avoid too many API calls
  const debouncedSearch = React.useMemo(
    () => debounce(async (query) => {
      if (!query.trim()) {
        // If search is cleared and we have a use case, get templates for that use case
        if (selectedUseCase) {
          onSearch(null, selectedUseCase);
        } else {
          // Otherwise, clear the templates
          setTemplateNames([]);
        }
        return;
      }
      setIsSearching(true);
      try {
        // Search across all templates, use case is optional
        const results = await onSearch(query, selectedUseCase);
        setTemplateNames(results);
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setIsSearching(false);
      }
    }, 300),
    [onSearch, selectedUseCase, setTemplateNames]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    // Don't clear selectedTemplate here, only when actually selecting a new one
    
    if (!query.trim()) {
      onSearch('', selectedUseCase);
      return;
    }
    debouncedSearch(query);
  };
  
  // Add clear functionality
  const clearSearch = () => {
    setSearchQuery('');
    setSelectedTemplate('');
    onSearch('', selectedUseCase); // This will set isSearchMode to false
  };

  return (
    <div className="relative">
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon 
            className={`h-5 w-5 ${isSearching ? 'text-indigo-500' : 'text-gray-400'}`} 
            aria-hidden="true" 
          />
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          className="block w-full rounded-md border-gray-300 pl-10 pr-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Search templates..."
        />
        {searchQuery && (
          <button
            onClick={clearSearch}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" aria-hidden="true" />
          </button>
        )}
      </div>
      {isSearching && (
        <div className="absolute right-0 top-0 bottom-0 flex items-center pr-10">
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-indigo-500"></div>
        </div>
      )}
    </div>
  );
};

export default PromptTemplateSearch;